import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

import "assets/css/bootstrap.min.css";
import "assets/scss/now-ui-kit.scss?v=1.5.0";
import "assets/demo/demo.css?v=1.5.0";
import "assets/demo/nucleo-icons-page-styles.css?v=1.5.0";

import HomeIndex from "../../policyFund_web/src/views/pages/home/homeIndex";
import StepIndex from "./views/pages/step/stepIndex";
import SearchIndex from "./views/pages/search/searchIndex";
import ReservationIndex from "./views/pages/reservation/reservationIndex";
import LoginIndex from "./views/pages/login/loginIndex";
import Find from "./views/pages/login/Find";
import Reset from "./views/pages/login/Reset";
import Join from "./views/pages/login/Join";
import {RecoilRoot} from "recoil";
import SearchDetail from "./views/pages/search/SearchDetail";

ReactDOM.render(
    <RecoilRoot>
        <BrowserRouter>
            <Switch>
                <Switch>
                    <Route
                        path="/home"
                        render={(props) => <HomeIndex {...props} />}
                    />
                    <Route
                        path="/login"
                        render={(props) => <LoginIndex {...props} />}
                    />
                    <Route
                        path="/find"
                        render={(props) => <Find {...props} />}
                    />
                    <Route
                        path="/reset"
                        render={(props) => <Reset {...props} />}
                    />
                    <Route
                        path="/join"
                        render={(props) => <Join {...props} />}
                    />
                    <Route
                        path="/step"
                        render={(props) => <StepIndex {...props} />}
                    />
                    <Route
                        path="/search"
                        render={(props) => <SearchIndex {...props} />}
                    />
                    <Route
                        path="/searchDetail/:id"
                        render={(props) => <SearchDetail {...props} />}
                    />
                    <Route
                        path="/reservation"
                        render={(props) => <ReservationIndex {...props} />}
                    />
                    <Route
                        path="/robots.txt"
                        render={(props) => <ReservationIndex {...props} />}
                    />
                    <Redirect to="/home" />
                    <Redirect from="/" to="/home" />
                </Switch>
            </Switch>
        </BrowserRouter>
    </RecoilRoot>,
    document.getElementById("root")
);
