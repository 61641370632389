import React, {useState} from "react";
import {useHistory} from "react-router-dom";
import styled from "styled-components";
// reactstrap components
import {Col, Input, Row} from "reactstrap";
import {isMobile} from "react-device-detect";
import {logo} from "../../assets/images";
import {cancelButton, logoM, mainImageM, menubar, searchIcon} from "../../assets/imagesM";
import Header from "quill/formats/header";
import AuthService from "../../views/pages/login/service/auth.service";

const NavButton = styled.div`
  font-weight: 700;
  padding: 0.8rem 0 0.5rem;
  font-size: 1em;
  color: black;
  cursor: pointer;
`;

const NavLogo = styled.div`
  font-weight: 700;
  font-size: 1.1rem;
  color: white;
  cursor: pointer;
  border: none;

  @media screen and (max-width: 800px) {
    font-size: 0.8rem;
  }
`;

const NavLogoM = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  line-height: 30px;
  padding: 0.7rem 0.7rem;
  color: white;
  border-bottom: 0.5px solid lightgray;
`;

function IndexHeader() {
  const history = useHistory();
  const isHome = history.location.pathname === '/home';
  const isIntro = history.location.pathname === '/introduce';
  const isPortfolio = history.location.pathname === '/portfolio';
  const isInquiry = history.location.pathname === '/inquiry';
  const [menu, setMenu] = useState(false)

  const isCheck = typeof window !== 'undefined' ? AuthService.getCurrentUser() : null;


  const menuColor = (diff) => {
    if(diff === true) {
      return{color: '#0047AB', pointerEvents: 'none', backgroundColor: 'transparent'}

    }
    if(diff === false) {
      return{color: 'black', textDecorator: 'none', backgroundColor: 'transparent' }
    }
  }

  return (
    <>
      {isMobile
      ?
        <div style={{ fontFamily: 'regular' }}>
          <span>
            <NavLogoM>
              <img src={logoM} style={{ width: '30%', padding: '2% 0' }} onClick={() => history.push('/home')}/>
              {menu
                  ? <img src={cancelButton} style={{ width: '10%' }} onClick={()=> setMenu(!menu)}/>
                  : <img src={menubar} style={{ width: '10%' }} onClick={()=> setMenu(!menu)}/>
              }
            </NavLogoM>
          </span>
          {menu &&
            <div
              style={{
                position: 'fixed',
                zIndex: 100,
                height: '100%',
                width: '100%',
                backgroundColor: 'white',

              }}
            >
              {/*<div style={{ padding: '5% 3%', borderTop: '3px solid #f8f8f8', borderBottom: '5px solid #f8f8f8' }}>*/}
              {/*  <img src={searchIcon} style={{ width: '5%', position: 'absolute', marginTop: '1.5%', marginLeft: '85%' }}/>*/}
              {/*  <Input*/}
              {/*    style={{ padding: '2% 4%', fontSize: '0.8rem' }}*/}
              {/*    placeholder="검색어를 입력하세요!"*/}
              {/*    type="text"*/}
              {/*  />*/}
              {/*</div>*/}
              <div style={{ borderBottom: '2px solid #f8f8f8' }} onClick={() => history.push('/step')}>
                <div style={{ padding: '5% 3% 5% 3%', fontSize: '1.5rem', fontWeight: '800' }}>
                  지원 절차
                </div>
                {/*<div style={{ padding: '2% 3% 5% 3%', fontSize: '1rem', fontWeight: '500' }}>*/}
                {/*  지원 절차*/}
                {/*</div>*/}
              </div>
              <div style={{ borderBottom: '2px solid #f8f8f8' }} onClick={() => history.push('/search')}>
                <div style={{ padding: '5% 3% 5% 3%', fontSize: '1.5rem', fontWeight: '800' }}>
                  지원사업 조회
                </div>
                {/*<div style={{ padding: '3% 3% 1% 3%', fontSize: '1rem', fontWeight: '500' }}>*/}
                {/*  지원 절차*/}
                {/*</div>*/}
                {/*<div style={{ padding: '1% 3% 1% 3%', fontSize: '1rem', fontWeight: '500' }}>*/}
                {/*  기업생애주기별 지원사업 조회*/}
                {/*</div>*/}
                {/*<div style={{ padding: '1% 3% 1% 3%', fontSize: '1rem', fontWeight: '500' }}>*/}
                {/*  사업공고 조회  신청*/}
                {/*</div>*/}
                {/*<div style={{ padding: '1% 3% 5% 3%', fontSize: '1rem', fontWeight: '500' }}>*/}
                {/*  더 많은 정책사업 조회*/}
                {/*</div>*/}
              </div>
              <div style={{ borderBottom: '2px solid #f8f8f8' }} onClick={() => history.push('/reservation')}>
                <div style={{ padding: '5% 3% 5% 3%', fontSize: '1.5rem', fontWeight: '800' }}>
                  상담 예약
                </div>
              </div>
              <div style={{ margin: '0 auto', textAlign: 'center', paddingTop: '20%' }}>
                {isCheck
                ?
                    <div style={{ fontSize: '1rem', fontWeight: '500' }}>
                      <span
                          onClick={() => {
                            history.push('/login')
                            AuthService.logout()
                      }}>
                        로그아웃
                      </span>
                    </div>
                :
                    <div style={{ fontSize: '1rem', fontWeight: '500' }}>
                      <span onClick={() => history.push('/login')}>로그인</span>
                      <sapn style={{ margin: '0 3%' }}>|</sapn>
                      <span onClick={() => history.push('/join')}>회원가입</span>
                    </div>
                }
              </div>
            </div>
          }
        </div>
      :
        <div style={{ fontFamily: 'regular' }}>
          <Row style={{borderBottomStyle: 'solid', borderBottomColor: '#e8e8e8', borderBottomWidth: '0.5px', justifyContent: 'center' }}>
            <Col
              md="4"
              xs="auto"
              style={{
                lineHeight: '83px',
                display: 'flex',
              }}
              onClick={() => history.push('/home')}
            >
              <NavLogo>
                <img src={logo} style={{ width: '30%' }}/>
              </NavLogo>
            </Col>
            <Col md="4" className="text-right mt-4" style={{ lineHeight: '50px' }}>
              <Row>
                <Col
                  className="mt-2"
                  onClick={() => history.push('/login')}
                >
                  {isCheck
                      ?
                      <>
                        <h6 style={{ cursor: 'pointer', fontSize: '1rem' }} onClick={() => AuthService.logout()}>로그아웃</h6>
                      </>
                      :
                      <>
                        <h6 style={{ cursor: 'pointer', fontSize: '1rem' }}>로그인</h6>
                      </>
                  }
                </Col>
                <Col
                    md="auto"
                    className="mt-2"
                    onClick={() => history.push('/join')}
                >
                  {!isCheck &&
                      <>
                        <h6 style={{ cursor: 'pointer', fontSize: '1rem' }}><span style={{ color: 'lightgray' }}>|</span></h6>
                      </>
                  }
                </Col>
                <Col
                    md="auto"
                  className="mt-2"
                  onClick={() => history.push('/join')}
                >
                  {!isCheck &&
                      <>
                        <h6 style={{ cursor: 'pointer', fontSize: '1rem' }}>회원가입</h6>
                      </>
                  }
                </Col>
              </Row>
            </Col>
          </Row>
          <Row style={{ borderBottomStyle: 'solid', borderBottomColor: '#e8e8e8', borderBottomWidth: '0.5px', justifyContent: 'center' }}>
            <Col md="8">
              <Row>
                <Col md="2" xs="auto">
                  <NavButton
                    style={menuColor(isIntro)}
                    onClick={() => history.push('/step')}
                  >
                      <h5 className="mt-2 font-weight-bold">지원절차</h5>
                  </NavButton>
                </Col>
                <Col md="2" xs="auto">
                  <NavButton
                    style={menuColor(isPortfolio)}
                    onClick={() => history.push('/search')}
                  >
                      <h5 className="mt-2 font-weight-bold">지원사업 조회</h5>
                  </NavButton>
                </Col>
                <Col md="2" xs="auto">
                  <NavButton
                      style={menuColor(isInquiry)}
                      onClick={() => history.push('/reservation')}
                  >
                        <h5 className="mt-2 font-weight-bold">상담 예약</h5>
                  </NavButton>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      }
    </>
  );
}

export default IndexHeader;
