import {Card, Col, Row} from "reactstrap";
import React, {useEffect} from "react";
import {beforeIcon, main2Logo, nextIcon} from "../../../assets/images";
import {categoryList} from "./data/data";
import {isMobile} from "react-device-detect";
import {main2ImageM} from "../../../assets/imagesM";
import Slider from "react-slick";
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import searchService from "../search/service/search.service";
import useHistoryState from "../../../common/useHistoryState";
import {useHistory} from "react-router-dom";
import {useRecoilState} from "recoil";
import {filePreviewState} from "../search/data/data";
import moment from "moment";
import countHistoryService from "./service/countHistory.service";


function Home() {
    const history = useHistory();
    const [activeIndex, setActiveIndex] = React.useState(0);
    const [animating, setAnimating] = React.useState(false);

    const [pageActive, setPageActive] = useHistoryState(1, 'pageActive');
    const [pageCount, setPageCount] = useHistoryState(1, 'pageCount');
    const [limit, setLimit] = useHistoryState(20, 'limit');
    const [sort, setSort] = useHistoryState('desc', 'limit');
    const [data, setData] = useHistoryState([], 'data');
    const [bgImage,setBgImage] = useHistoryState([], 'bgImage');
    const [category, setCategory] = useRecoilState(filePreviewState);

    const render = () => {
        searchService.list({ page: pageActive, limit, sort, status: '진행중' })
            .then((res) => res.data)
            .then((res) => {
                if (res.statusCode === 200) {
                    console.log(res);
                    setPageCount(res.list.count);
                    setData(res.list.rows);
                }
            });
    }

    useEffect(() => {
        render();
    }, [ pageActive, limit, sort ]);

    useEffect(async () => {
        await countHistoryService.register({
            date: moment().format('YYYY-MM-DD')
        });
    }, [  ]);


    const renderBg = () => {
        searchService.bgList({ })
            .then((res) => res.data)
            .then((res) => {
                if (res.statusCode === 200) {
                    console.log(res);
                    setBgImage(res.list);
                }
            });
    }

    useEffect(() => {
        renderBg();
    }, []);

    function SampleNextArrow(props) {
        const { className, style, onClick } = props;
        return (
            <img
                src={nextIcon}
                className={className}
                style={{ ...style, maxWidth: 'auto', maxHeight: 'auto' , minWidth: '50px', minHeight: '50px', right: '-100px' }}
                onClick={onClick}
            />
        );
    }

    function SamplePrevArrow(props) {
        const { className, style, onClick } = props;
        return (
            <img
                src={beforeIcon}
                className={className}
                style={{ ...style, maxWidth: 'auto', maxHeight: 'auto' , minWidth: '50px', minHeight: '50px', left: '-100px' }}
                onClick={onClick}
            />
        );
    }

    const settings = {
        dots: true,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        speed: 1000,
        autoplaySpeed: 3000,
        cssEase: "linear",
        pauseOnHover: true,
    };

    const settingsM = {
        dots: true,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        speed: 1000,
        autoplaySpeed: 3000,
        cssEase: "linear",
        pauseOnHover: true,
    };

    const settingsSearch = {
        dots: true,
        infinite: true,
        slidesToShow: 3,
        slidesToScroll: 3,
        autoplay: true,
        speed: 1000,
        autoplaySpeed: 3000,
        cssEase: "linear",
        pauseOnHover: true,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />,
        responsive: [
            {
                breakpoint: 2000,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    infinite: true,
                    dots: true
                }
            },{
                breakpoint: 1350,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    initialSlide: 1
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };

    return (
        <>
            {isMobile
            ?
                <div style={{ fontFamily: 'regular' }}>
                    <div style={{ width: '88%', margin: '0 auto', marginTop: '5%' }}>
                        <Slider {...settingsM}>
                            {bgImage?.map((v) => {
                                return <>
                                    <div
                                        onClick={() => window.location.href = v.url}
                                        // onClick={() => {
                                        //     window.open(v.url)
                                        // }}
                                        style={{ cursor: 'pointer' }}
                                    >
                                        <img src={v.thumbnailM}/>
                                    </div>
                                </>
                            })}
                        </Slider>
                    </div>
                    <div style={{ textAlign: 'center', padding: '20% 0' }}>
                        <div style={{ fontWeight: '800', fontSize: '2rem', paddingBottom: '2%' }}>지원사업 안내</div>
                        <p className="font-weight-normal">중소기업 지원사업을<br/>조회하고 신청할 수 있습니다.</p>
                        <Row style={{ display: 'flex', margin: '0', paddingTop: '10%' }}>
                            {categoryList.map((v) => {
                                return <>
                                    <div style={{ display: 'flex', flexDirection: 'column', width: '50%', marginTop: '8%' }}>
                                        <img
                                            src={v.image}
                                            style={{ width: '70%', margin: '0 auto' }}
                                            onClick={(e) => {
                                                setCategory(v.name)
                                                history.push('/search')
                                            }}
                                        />
                                    </div>
                                </>
                            })}
                        </Row>
                    </div>
                    <div style={{ backgroundColor: '#f8f8f8', textAlign: 'center', padding: '20% 0' }}>
                        <div style={{ fontWeight: '500', fontSize: '1rem', paddingBottom: '2%', color: '#0061d3' }}>
                            진행중인 공고
                            <span style={{ backgroundColor: '#0061d3', color: 'white', borderRadius: '30px', padding: '2% 3% 1% 3%', marginLeft: '3%' }}>
                                7 건
                            </span>
                        </div>
                        <div style={{ fontWeight: '800', fontSize: '2rem', paddingBottom: '2%' }}>지원사업 공고</div>
                        <p className="font-weight-normal">로그인 후 내게 맞는<br/>지원 사업을 볼 수 있습니다.</p>
                        <img src={main2ImageM} style={{ width: '80%', paddingLeft: '15%' }}/>
                        <div style={{ width: '88%', margin: '0 auto' }}>
                            <Slider {...settingsM}>
                                {data?.map((v) => {
                                    return <>
                                        <Col>
                                            <Card
                                                onClick={(e) => history.push('/searchDetail/' + v.id)}
                                                className="p-5"
                                                style={{
                                                    minHeight: '400px',
                                                    manHeight: '400px',
                                                    fontWeight: '400',
                                                    borderRadius: '15px',
                                                    cursor: 'pointer',
                                                    boxShadow: 'none',
                                                    border: '1px solid #5479f7',
                                                }}
                                            >
                                                <Row>
                                                    <Col className="text-left font-weight-bolder">
                                                        <span style={{ fontSize: '1.5rem', fontWeight: '700' }}>{v.title}</span>
                                                    </Col>
                                                </Row>
                                                <Row className="mt-2" style={{ paddingBottom: '100px' }}>
                                                    <Col className="text-left" style={{ fontSize: '1.2rem' }}>
                                                        {v.description?.length > 60
                                                            ? v.description.substring(0, 60) + " ...."
                                                            : v.description
                                                        }
                                                    </Col>
                                                </Row>
                                                <Row style={{ bottom: '0', position: 'absolute' }} className="mb-4">
                                                    <div
                                                        md="auto"
                                                        className="mr-4 px-4"
                                                        style={{ border: '1px solid #5479f7', borderRadius: '25px', fontSize: '0.9rem', lineHeight: '35px', color: '#5479f7' }}
                                                    >
                                                        {v.category}
                                                    </div>
                                                    <div
                                                        md="auto"
                                                        className="px-4"
                                                        style={{ color: 'white', backgroundColor: '#5479f7', borderRadius: '25px', fontSize: '0.9rem', lineHeight: '35px' }}
                                                    >
                                                        기간 : {v.term}
                                                    </div>
                                                </Row>
                                            </Card>
                                        </Col>
                                    </>
                                })}
                            </Slider>
                        </div>
                    </div>
                </div>
            :
                <div style={{ fontFamily: 'regular' }}>
                    <Row className="justify-content-center mt-5">
                        <Col lg="8" md="8" xs="10">
                            {/*<div type="button" style={{ zIndex: '1000', position: 'absolute' }}>신청하러가기</div>*/}
                            <Slider {...settings}>
                                {bgImage?.map((v) => {
                                    return <>
                                        <Row
                                            // onClick={() => {
                                            //     window.open(v.url)
                                            // }}
                                            onClick={() => window.location.href = v.url}
                                            style={{ cursor: 'pointer' }}
                                            className="mb-3"
                                        >
                                            <img src={v.thumbnail}/>
                                        </Row>
                                    </>
                                })}
                            </Slider>
                        </Col>
                    </Row>
                    <Row style={{ padding: '5% 0' }}>
                        <Col lg="12" md="12" className="text-center mt-5">
                            <span style={{ fontSize: '3rem', fontWeight: '700' }}>지원사업 안내</span>
                            <p className="font-weight-normal">중소기업 지원사업을 조회하고 신청할 수 있습니다.</p>
                            {categoryList.map((v) => {
                                return <>
                                    <img
                                        src={v.image}
                                        style={{ width: '5%', margin: '2% 1%', cursor: 'pointer' }}
                                        onClick={(e) => {
                                            setCategory(v.name)
                                            history.push('/search')
                                        }}/>
                                </>
                            })}
                        </Col>
                    </Row>
                    <Row style={{ backgroundColor: '#f8f8f8', padding: '5% 24% 0 24%' }}>
                        <Col md="6">
                            <Row className="">
                                <h5 style={{ color: '#0061d3', marginTop: '0.2rem', marginRight: '0.5rem' }}>진행중인 공고</h5>
                                <h5 style={{ padding: '0.2rem 0.5rem 0.1rem', backgroundColor: '#5479f7', color: 'white', borderRadius: '15px', fontWeight: '650' }}>{pageCount}건</h5>
                            </Row>
                            <Row>
                                <span style={{ fontSize: '3rem', fontWeight: '700' }}>지원사업 공고</span>
                            </Row>
                            <Row>
                                <p className="font-weight-normal">로그인 후 내게 맞는 지원 사업을 볼 수 있습니다.</p>
                            </Row>
                        </Col>
                        <Col md="6" className="text-right">
                            <img src={main2Logo} style={{ width: '50%' }}/>
                        </Col>
                    </Row>
                    <Row className="justify-content-center" style={{ backgroundColor: '#f8f8f8', paddingBottom: '55px' }}>
                        <Col lg="6" md="6" xs="6">
                            <Slider {...settingsSearch}>
                                {data?.map((v) => {
                                    return <>
                                        <Col>
                                            <Card
                                                onClick={(e) => history.push('/searchDetail/' + v.id)}
                                                className="p-5"
                                                style={{
                                                    minHeight: '450px',
                                                    manHeight: '450px',
                                                    fontWeight: '400',
                                                    borderRadius: '15px',
                                                    cursor: 'pointer',
                                                    boxShadow: 'none',
                                                    border: '1px solid #5479f7',
                                                }}
                                            >
                                                <Row>
                                                    <Col className="text-left font-weight-bolder">
                                                        <span style={{ fontSize: '1.5rem', fontWeight: '700' }}>{v.title}</span>
                                                    </Col>
                                                </Row>
                                                <Row className="mt-2" style={{ paddingBottom: '100px' }}>
                                                    <Col className="text-left" style={{ fontSize: '1.2rem' }}>
                                                        {v.description?.length > 80
                                                            ? v.description.substring(0, 80) + " ...."
                                                            : v.description
                                                        }
                                                    </Col>
                                                </Row>
                                                <Row style={{ bottom: '0', position: 'absolute' }} className="mb-4">
                                                    <Col
                                                        md="auto"
                                                        className="mr-4"
                                                        style={{ border: '1px solid #5479f7', borderRadius: '25px', fontSize: '0.9rem', lineHeight: '35px', color: '#5479f7' }}
                                                    >
                                                        {v.category}
                                                    </Col>
                                                    <Col
                                                        md="auto"
                                                        style={{ color: 'white', backgroundColor: '#5479f7', borderRadius: '25px', fontSize: '0.9rem', lineHeight: '35px' }}
                                                    >
                                                        기간 : {v.term}
                                                    </Col>
                                                </Row>
                                            </Card>
                                        </Col>
                                    </>
                                })}
                            </Slider>
                        </Col>
                    </Row>
                </div>
            }
        </>
    )
}

export default Home;