import React from 'react';
import {Pagination, PaginationItem, PaginationLink,} from "reactstrap";
import {FaAngleDoubleLeft, FaAngleDoubleRight, FaAngleLeft, FaAngleRight} from "react-icons/fa";

const PaginationWrapper = (props) => {
  let { isActive, pageNumber, itemPerPage, callback, limit } = props;
  let possiblePageMax = 1;
  let pageMin = 1;
  let pageMax = 1;
  let lastPage = Math.ceil(pageNumber / limit)

  let pageNumbers = [];

  possiblePageMax = Math.ceil(pageNumber / limit);
  if (possiblePageMax === 0) possiblePageMax = 1;
  // console.log('possiblePageMax',possiblePageMax)

  // 페이지 최소 범위
  pageMin = (Math.ceil(isActive / itemPerPage) - 1) * itemPerPage + 1;
  if (pageMin < 1) pageMin = 1;

  // 페이지 최대 범위
  pageMax = pageMin + itemPerPage - 1;
  if (possiblePageMax < pageMax) pageMax = possiblePageMax;
  // console.log(pageMin, pageMax)

  // 페이지 엘레먼트
  pageNumbers = [];
  for (let i = pageMin; i <= pageMax; i++) pageNumbers = [...pageNumbers, i];
  // console.log('===',pageNumbers, '===')

  const active = (selectedPage) => {
    if (typeof callback == 'function') callback(selectedPage);
    isActive = selectedPage;
  };

  const next = () => {
    let wantToGo = isActive + 1;
    if (wantToGo <= possiblePageMax) {
      isActive = wantToGo;
      if (typeof callback == 'function') callback(isActive);
    }
  };

  const goEnd = () => {
    isActive = possiblePageMax;
    if (typeof callback == 'function') callback(isActive);
  };

  const prev = () => {
    let wantToGo = isActive - 1;
    if (wantToGo < 1) {
      isActive = 1;
    } else {
      isActive = wantToGo;
    }
    if (typeof callback == 'function') callback(isActive);
  };

  const goStart = () => {
    isActive = 1;
    if (typeof callback == 'function') callback(isActive);
  };

  return (
      <nav aria-label="...">
        <Pagination
            className="pagination justify-content-center mb-0"
            listClassName="justify-content-center mb-0"
        >
          <PaginationItem className={isActive === 1 ? "disabled" : ""}>
            <PaginationLink
                onClick={goStart}
                tabIndex="-1"
            >
              <FaAngleDoubleLeft />
              <span className="sr-only">Start</span>
            </PaginationLink>
          </PaginationItem>

          <PaginationItem className={isActive === 1 ? "disabled" : ""}>
            <PaginationLink
                onClick={prev}
                tabIndex="-1"
            >
              <FaAngleLeft />
              <span className="sr-only">Previous</span>
            </PaginationLink>
          </PaginationItem>

          {pageNumbers.map((page) => (
              <PaginationItem key={page} className={page === isActive ? "active": ""}>
                <PaginationLink
                    color="primary"
                    onClick={() => active(page)}
                >
                  {page}
                </PaginationLink>
              </PaginationItem>
          ))}

          <PaginationItem className={isActive === lastPage ? "disabled" : ""}>
            <PaginationLink
                onClick={next}
            >
              <FaAngleRight />
              <span className="sr-only">Next</span>
            </PaginationLink>
          </PaginationItem>

          <PaginationItem className={isActive === lastPage ? "disabled" : ""}>
            <PaginationLink
                onClick={goEnd}
            >
              <FaAngleDoubleRight />
              <span className="sr-only">End</span>
            </PaginationLink>
          </PaginationItem>

        </Pagination>
      </nav>
  );
};

export default PaginationWrapper;
