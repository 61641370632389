/*!

=========================================================
* Argon Dashboard PRO React - v1.2.1
=========================================================

* Theme Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
/*eslint-disable*/

// reactstrap components
import {NavItem, NavLink, Nav, Container, Row, Col, Input, Modal, Button, ModalBody, ModalHeader} from "reactstrap";
import {isMobile} from "react-device-detect";
import {footer, logo} from "../../assets/images";
import {footerM} from "../../assets/imagesM";
import Slider from "react-slick";
import {useState} from "react";

function AdminFooter() {
    const [modalChange, setModalChange] = useState(false);
    const [diff, setDiff] = useState('');

    const openNewWindow = () => {
        // 새 창을 열기 위해 window.open() 함수를 사용합니다.
        // 여기서 'your_file.html' 부분은 열고자 하는 HTML 파일의 경로를 입력해야 합니다.
        window.open('https://policy-fund-backend-images.s3.ap-northeast-2.amazonaws.com/userPolicy.html', '_blank');
    }

    const openNewWindow2 = () => {
        // 새 창을 열기 위해 window.open() 함수를 사용합니다.
        // 여기서 'your_file.html' 부분은 열고자 하는 HTML 파일의 경로를 입력해야 합니다.
        window.open('https://policy-fund-backend-images.s3.ap-northeast-2.amazonaws.com/serviceAgreement.html', '_blank');
    }

  return (
      <>
          {isMobile
              ?
              <div style={{ fontFamily: 'regular' }}>
                  <div style={{ backgroundColor: '#dadada77', minHeight: '230px', display: 'flex' }} className="justify-content-center">
                      <Col md="8">
                          <Row style={{ fontSize: '1rem', fontWeight: '500', marginTop: '10%' }}>
                              <Col>
                              <span
                                  md="auto"
                                  onClick={() => {
                                      setDiff('이용약관')
                                      setModalChange(true)
                                  }}
                                  style={{ cursor: 'pointer' }}
                              >
                                  이용약관
                              </span>
                              <span md="auto" className="text-muted font-weight-normal mx-4">|</span>
                              <span
                                  md="auto"
                                  onClick={() => {
                                      setDiff('개인정보')
                                      setModalChange(true)
                                  }}
                                  style={{ cursor: 'pointer' }}
                              >
                                  개인정보처리방침
                              </span>
                              </Col>
                          </Row>
                          <Row style={{ fontSize: '1.1rem', fontWeight: '750' }} className="mt-5">
                              <Col md="auto">정책자금24</Col>
                          </Row>
                          <Row style={{ fontSize: '1.1rem', fontWeight: '500' }} className="mt-3">
                              <Col md="auto">고객센터: 1670-2375~6</Col>
                          </Row>
                      </Col>
                  </div>
                  <Modal
                      isOpen={modalChange}
                      toggle={() => setModalChange(false)}
                      className="modal-dialog-centered"
                      style={{ minWidth: '60%' }}
                      fade={false}
                  >
                      <ModalHeader>
                          <h6 className="modal-title">
                              {diff !== '이용약관' ? '개인정보처리방침' : '이용약관'
                              }
                          </h6>
                          <button
                              aria-label="Close"
                              className="close"
                              data-dismiss="modal"
                              type="button"
                              onClick={() => {
                                  setModalChange(false)
                              }}
                          >
                              ×
                          </button>
                      </ModalHeader>
                      <ModalBody>
                          <Row className="mt-3">
                              <Col md="auto">
                                  {diff === '이용약관'
                                      ?
                                      <>
                                          제1장 총칙<br/>
                                          <br/>
                                          제1조 [목적]<br/>
                                          이 약관은 정책자금24 는 온라인으로 제공하는 디지털콘텐츠(이하 “콘텐츠”라고 한다) 및 제반서비스의 이용과 관련하여 정책자금24와 이용자와의 권리, 의무 및 책임사항 등을 규정함을 목적으로 합니다.<br/>
                                          <br/>
                                          제2조 [정의]<br/>
                                          이 약관에서 사용하는 용어의 정의는 다음과 같습니다.<br/>
                                          1. “정책자금24”라 함은 “콘텐츠” 산업과 관련된 경제활동을 영위하는 자로서 콘텐츠 및 컨설팅서비스를 제공하는 자를 말합니다.<br/>
                                          2. “이용자”라 함은 “정책자금24”의 사이트에 접속하여 이 약관에 따라 “정책자금24”가 제공하는 “콘텐츠” 및 제반서비스를 이용하는 회원 및 비회원을 말합니다.<br/>
                                          3. “회원”이라 함은 “정책자금24”와(과) 이용계약을 체결하고 “이용자” 아이디(ID)를 부여받은 “이용자”로서 “정책자금24”의 정보를 지속적으로 제공받으며 “정책자금24”가(이) 제공하는 서비스를 지속적으로 이용할 수 있는 자를 말합니다.<br/>
                                          4. “비회원”이라 함은 “회원”이 아니면서 “정책자금24”가(이) 제공하는 서비스를 이용하는 자를 말합니다.<br/>
                                          5. “콘텐츠”라 함은 정보통신망이용촉진 및 정보보호 등에 관한 법률 제2조 제1항 제1호의 규정에 의한 정보통신망에서 사용되는 부호·문자·음성·음향·이미지 또는 영상 등으로 표현된 자료 또는 정보로서, 그 보존 및 이용에 있어서 효용을 높일 수 있도록 전자적 형태로 제작 또는 처리된 것을 말합니다.<br/>
                                          6. “아이디(ID)”라 함은 “회원”의 식별과 서비스이용을 위하여 “회원”이 정하고 “정책자금24”가(이) 승인하는 문자 또는 숫자의 조합을 말합니다.<br/>
                                          7. “비밀번호(PASSWORD)”라 함은 “회원”이 부여받은 “아이디”와 일치되는 “회원”임을 확인하고 비밀보호를 위해 “회원” 자신이 정한 문자 또는 숫자의 조합을 말합니다.<br/>
                                          <br/>
                                          제3조 [신원정보 등의 제공]<br/>
                                          “정책자금24”는(은) 이 약관의 내용, 상호, 대표자 성명, 영업소 소재지 주소(소비자의 불만을 처리할 수 있는 곳의 주소를 포함), 전화번호, 모사전송번호, 전자우편주소, 사업자등록번호, 통신판매업 신고번호 및 개인정보관리책임자 등을 이용자가 쉽게 알 수 있도록 온라인 서비스초기화면에 게시합니다. 다만, 약관은 이용자가 연결화면을 통하여 볼 수 있도록 할 수 있습니다.<br/>
                                          <br/>
                                          제4조 [약관의 게시 등]<br/>
                                          ① “정책자금24”는(은) 이 약관을 “회원”이 그 전부를 인쇄할 수 있고 거래과정에서 해당 약관의 내용을 확인할 수 있도록 기술적 조치를 취합니다.<br/>
                                          ② “정책자금24”는(은) “이용자”가 “기업성장진흥연구소”와(과) 이 약관의 내용에 관하여 질의 및 응답할 수 있도록 기술적 장치를 설치합니다.<br/>
                                          ③ “정책자금24”는(은) “이용자”가 약관에 동의하기에 앞서 약관에 정하여져 있는 내용 중 청약철회, 환불조건 등과 같은 중요한 내용을 이용자가 쉽게 이해할 수 있도록 별도의 연결화면 또는 팝업화면 등을 제공하여 “이용자”의 확인을 구합니다.<br/>
                                          <br/>
                                          제5조 [약관의 개정 등]<br/>
                                          ① “정책자금24”는(은) 온라인 디지털콘텐츠산업 발전법, 전자상거래 등에서의 소비자보호에 관한 법률, 약관의 규제에 관한 법률 등 관련법을 위배하지 않는 범위에서 이 약관을 개정할 수 있습니다.<br/>
                                          ② “정책자금24”가(이) 약관을 개정할 경우에는 적용일자 및 개정사유를 명시하여 현행약관과 함께 서비스초기화면에 그 적용일자 7일 이전부터 적용일 후 상당한 기간동안 공지하고, 기존회원에게는 개정약관을 전자우편주소로 전송합니다.<br/>
                                          ③ “정책자금24”가(이) 약관을 개정할 경우에는 개정약관 공지 후 개정약관의 적용에 대한 “이용자”의 동의 여부를 확인합니다. “이용자”가 개정약관의 적용에 동의하지 않는 경우 “정책자금24” 또는 “이용자”는 콘텐츠 이용계약을 해지할 수 있습니다. 이때, “정책자금24”는(은) 계약해지로 인하여 “이용자”가 입은 손해를 배상합니다.<br/>
                                          <br/>
                                          제6조 [약관의 해석]<br/>
                                          이 약관에서 정하지 아니한 사항과 이 약관의 해석에 관하여는 온라인 디지털콘텐츠산업 발전법, 전자상거래 등에서의 소비자보호에 관한 법률, 약관의 규제에 관한 법률, 정보통신부장관이 정하는 디지털콘텐츠이용자보호지침, 기타 관계법령 또는 상관례에 따릅니다.<br/>
                                          <br/>
                                          제2장 회원가입<br/>
                                          <br/>
                                          제7조 [회원가입]<br/>
                                          ① 회원가입은 “이용자”가 약관의 내용에 대하여 동의를 하고 회원가입신청을 한 후 “정책자금24”가(이) 이러한 신청에 대하여 승낙함으로써 체결됩니다.<br/>
                                          ② 회원가입신청서에는 다음 사항을 기재해야 합니다. 1호 내지 3호의 사항은 필수사항이며, 그 외의 사항은 선택사항입니다.<br/>
                                          1. “회원”의 성명 또는 인터넷상 개인식별번호<br/>
                                          2. “아이디”와 “비밀번호”<br/>
                                          3. 전자우편주소<br/>
                                          4. 이용하려는 “콘텐츠”의 종류<br/>
                                          5. 기타 “정책자금24”가(이) 필요하다고 인정하는 사항<br/>
                                          ③ “정책자금24”는(은) 상기 “이용자”의 신청에 대하여 회원가입을 승낙함을 원칙으로 합니다. 다만, “정책자금24”는(은) 다음 각 호에 해당하는 신청에 대하여는 승낙을 하지 않을 수 있습니다.<br/>
                                          1. 가입신청자가 이 약관에 의하여 이전에 회원자격을 상실한 적이 있는 경우<br/>
                                          2. 실명이 아니거나 타인의 명의를 이용한 경우<br/>
                                          3. 허위의 정보를 기재하거나, 기업성장진흥연구소가(이) 제시하는 내용을 기재하지 않은 경우<br/>
                                          4. 이용자의 귀책사유로 인하여 승인이 불가능하거나 기타 규정한 제반 사항을 위반하며 신청하는 경우<br/>
                                          ④ “정책자금24”는(은) 서비스 관련 설비의 여유가 없거나, 기술상 또는 업무상 문제가 있는 경우에는 승낙을 유보할 수 있습니다.<br/>
                                          ⑤ 제3항과 제4항에 따라 회원가입신청의 승낙을 하지 아니하거나 유보한 경우, “정책자금24”는(은) 이를 신청자에게 알려야 합니다. “기업성장진흥연구소”의 귀책사유 없이 신청자에게 통지할 수 없는 경우에는 예외로 합니다.<br/>
                                          ⑥ 회원가입계약의 성립 시기는 “정책자금24”의 승낙이 “이용자”에게 도달한 시점으로 합니다.<br/>
                                          <br/>
                                          제8조 [미성년자의 회원가입에 관한 특칙]<br/>
                                          ① 만 14세 미만의 “이용자”는 개인정보의 수집 및 이용목적에 대하여 충분히 숙지하고 부모 등 법정대리인의 동의를 얻은 후에 회원가입을 신청하고 본인의 개인정보를 제공하여야 합니다.<br/>
                                          ② 기업성장진흥연구소는(은) 부모 등 법정대리인의 동의에 대한 확인절차를 거치지 않은 14세 미만 이용자에 대하여는 가입을 취소 또는 불허합니다.<br/>
                                          ③ 만 14세 미만 “이용자”의 부모 등 법정대리인은 아동에 대한 개인정보의 열람, 정정, 갱신을 요청하거나 회원가입에 대한 동의를 철회할 수 있으며, 이러한 경우에 “정책자금24”는(은) 지체 없이 필요한 조치를 취해야 합니다.<br/>
                                          <br/>
                                          제9조 [회원정보의 변경]<br/>
                                          ① “회원”은 개인정보관리화면을 통하여 언제든지 자신의 개인정보를 열람하고 수정할 수 있습니다.<br/>
                                          ② “회원”은 회원가입신청 시 기재한 사항이 변경되었을 경우 온라인으로 수정을 하거나 전자우편 기타 방법으로 “정책자금24”에 대하여 그 변경사항을 알려야 합니다.<br/>
                                          ③ 제2항의 변경사항을 “기업성장진흥연구소”에 알리지 않아 발생한 불이익에 대하여 “정책자금24”는(은) 책임지지 않습니다.<br/>
                                          <br/>
                                          제10조 [“회원”의 “아이디” 및 “비밀번호”의 관리에 대한 의무]<br/>
                                          ① “회원”의 “아이디”와 “비밀번호”에 관한 관리책임은 “회원”에게 있으며, 이를 제3자가 이용하도록 하여서는 안 됩니다.<br/>
                                          ② “회원”은 “아이디” 및 “비밀번호”가 도용되거나 제3자에 의해 사용되고 있음을 인지한 경우에는 이를 즉시 “정책자금24”에 통지하고 “정책자금24”의 안내에 따라야 합니다.<br/>
                                          ③ 제2항의 경우에 해당 “회원”이 “정책자금24”에 그 사실을 통지하지 않거나, 통지한 경우에도 “정책자금24”의 안내에 따르지 않아 발생한 불이익에 대하여 “정책자금24”는(은) 책임지지 않습니다.<br/>
                                          <br/>
                                          제11조 [“회원”에 대한 통지]<br/>
                                          ① “정책자금24”가(이) “회원”에 대한 통지를 하는 경우 “회원”이 지정한 전자우편주소로 할 수 있습니다.<br/>
                                          ② “정책자금24”는(은) “회원” 전체에 대한 통지의 경우 7일 이상 “정책자금24”의 게시판에 게시함으로써 제1항의 통지에 갈음할 수 있습니다. 다만, “회원” 본인의 거래와 관련하여 중대한 영향을 미치는 사항에 대하여는 제1항의 통지를 합니다.<br/>
                                          <br/>
                                          제12조 [회원탈퇴 및 자격 상실 등]<br/>
                                          ① “회원”은 “정책자금24”에 언제든지 탈퇴를 요청할 수 있으며 “정책자금24”는(은) 즉시 회원탈퇴를 처리합니다.<br/>
                                          ② “회원”이 다음 각호의 사유에 해당하는 경우, “정책자금24”는(은) 회원자격을 제한 및 정지시킬 수 있습니다.<br/>
                                          1. 가입신청 시에 허위내용을 등록한 경우<br/>
                                          2. “기업성장진흥연구소”의 서비스이용대금, 기타 “정책자금24”의 서비스이용에 관련하여 회원이 부담하는 채무를 기일에 이행하지 않는 경우<br/>
                                          3. 다른 사람의 “정책자금24”의 서비스이용을 방해하거나 그 정보를 도용하는 등 전자상거래 질서를 위협하는 경우<br/>
                                          4. “정책자금24”을(를) 이용하여 법령 또는 이 약관이 금지하거나 공서양속에 반하는 행위를 하는 경우<br/>
                                          ③“정책자금24”가(이) 회원자격을 제한·정지시킨 후, 동일한 행위가 2회 이상 반복되거나 30일 이내에 그 사유가 시정되지 아니하는 경우<br/>
                                          “정책자금24”는(은) 회원자격을 상실시킬 수 있습니다.<br/>
                                          ④“정책자금24”가(이) 회원자격을 상실시키는 경우에는 회원등록을 말소합니다. 이 경우 “회원”에게 이를 통지하고, 회원등록 말소 전에 최소한 30일 이상의 기간을 정하여 소명할 기회를 부여합니다.<br/>
                                          <br/>
                                          <br/>
                                          <br/>
                                          제13조 [이용계약의 성립 등]<br/>
                                          ① “이용자”는 “정책자금24”가(이) 제공하는 다음 또는 이와 유사한 절차에 의하여 이용신청을 합니다. “정책자금24”는(은) 계약 체결 전에 각 호의 사항에 관하여 “이용자”가 정확하게 이해하고 실수 또는 착오 없이 거래할 수 있도록 정보를 제공합니다.<br/>
                                          1. “콘텐츠” 목록의 열람 및 선택<br/>
                                          2. 성명, 주소, 전화번호(또는 이동전화번호), 전자우편주소 등의 입력<br/>
                                          3. 약관내용, 청약철회가 불가능한 “콘텐츠”에 대해 “정책자금24”가(이) 취한 조치에 관련한 내용에 대한 확인<br/>
                                          4. 이 약관에 동의하고 위 제3호의 사항을 확인하거나 거부하는 표시(예, 마우스 클릭)<br/>
                                          5. “콘텐츠”의 이용신청에 관한 확인 또는 “정책자금24”의 확인에 대한 동의<br/>
                                          ② “정책자금24”의 승낙이 제16조 제1항의 수신확인통지형태로 “이용자”에게 도달한 시점에 계약이 성립한 것으로 봅니다.<br/>
                                          ③ “정책자금24”의 승낙의 의사표시에는 “이용자”의 이용신청에 대한 확인 및 서비스제공 가능여부, 이용신청의 정정·취소 등에 관한 정보 등을 포함합니다.<br/>
                                          <br/>
                                          제14조 [미성년자 이용계약에 관한 특칙]<br/>
                                          “기업성장진흥연구소”는(은) 만 20세 미만의 미성년이용자가 유료서비스를 이용하고자 하는 경우에 부모 등 법정 대리인의 동의를 얻거나, 계약체결 후 추인을 얻지 않으면 미성년자 본인 또는 법정대리인이 그 계약을 취소할 수 있다는 내용을 계약체결 전에 고지하는 조치를 취합니다.<br/>
                                          <br/>
                                          제15조 [수신확인통지·이용신청 변경 및 취소]<br/>
                                          ① “정책자금24”는(은) “이용자”의 이용신청이 있는 경우 “이용자”에게 수신확인통지를 합니다.<br/>
                                          ② 수신확인통지를 받은 “이용자”는 의사표시의 불일치 등이 있는 경우에는 수신확인통지를 받은 후 즉시 이용신청 변경 및 취소를 요청할 수 있고, “정책자금24”는(은) 서비스제공 전에 “이용자”의 요청이 있는 경우에는 지체 없이 그 요청에 따라 처리하여야 합니다. 다만, 이미 대금을 지불한 경우에는 청약철회 등에 관한 제27조의 규정에 따릅니다.<br/>
                                          <br/>
                                          제16조 [“정책자금24”의 의무]<br/>
                                          ① “정책자금24”는(은) 법령과 이 약관이 정하는 권리의 행사와 의무의 이행을 신의에 좇아 성실하게 하여야 합니다.<br/>
                                          ② “정책자금24”는(은) “이용자”가 안전하게 “콘텐츠”를 이용할 수 있도록 개인정보(신용정보 포함)보호를 위해 보안시스템을 갖추어야 하며 개인정보보호정책을 공시하고 준수합니다.<br/>
                                          ③ “정책자금24”는(은) “이용자”가 콘텐츠이용 및 그 대금내역을 수시로 확인할 수 있도록 조치합니다.<br/>
                                          ④ “정책자금24”는(은) 콘텐츠이용과 관련하여 “이용자”로부터 제기된 의견이나 불만이 정당하다고 인정할 경우에는 이를 지체없이 처리합니다. 이용자가 제기한 의견이나 불만사항에 대해서는 게시판을 활용하거나 전자우편 등을 통하여 그 처리과정 및 결과를 전달합니다.<br/>
                                          ⑤ “정책자금24”는(은) 이 약관에서 정한 의무 위반으로 인하여 “이용자”가 입은 손해를 배상합니다.<br/>
                                          <br/>
                                          제17조 [“이용자”의 의무]<br/>
                                          ① “이용자”는 다음 행위를 하여서는 안 됩니다.<br/>
                                          1. 신청 또는 변경 시 허위내용의 기재<br/>
                                          2. 타인의 정보도용<br/>
                                          3. “정책자금24”에 게시된 정보의 변경<br/>
                                          4. “정책자금24”가(이) 금지한 정보(컴퓨터 프로그램 등)의 송신 또는 게시<br/>
                                          5. “정책자금24”와(과) 기타 제3자의 저작권 등 지적재산권에 대한 침해<br/>
                                          6. “정책자금24” 및 기타 제3자의 명예를 손상시키거나 업무를 방해하는 행위<br/>
                                          7. 외설 또는 폭력적인 말이나 글, 화상, 음향, 기타 공서양속에 반하는 정보를 “정책자금24”의 사이트에 공개 또는 게시하는 행위<br/>
                                          8. 기타 불법적이거나 부당한 행위<br/>
                                          ② “이용자”는 관계법령, 이 약관의 규정, 이용안내 및 “콘텐츠”와 관련하여 공지한 주의사항, “정책자금24”가(이) 통지하는 사항 등을 준수하여야 하며, 기타 “정책자금24”의 업무에 방해되는 행위를 하여서는 안 됩니다.<br/>
                                          <br/>
                                          <br/>
                                          제18조 [개인정보보호]<br/>
                                          ① “정책자금24”는(은) 제7조 제2항의 신청서기재사항 이외에 “이용자”의 콘텐츠이용에 필요한 최소한의 정보를 수집할 수 있습니다. 이를 위해 “정책자금24”가(이) 문의한 사항에 관해 “이용자”는 진실한 내용을 성실하게 고지하여야 합니다.<br/>
                                          ② “정책자금24”가(이) “이용자”의 개인 식별이 가능한 “개인정보”를 수집하는 때에는 당해 “이용자”의 동의를 받습니다.<br/>
                                          ③ “정책자금24”는(은) “이용자”가 이용신청 등에서 제공한 정보와 제1항에 의하여 수집한 정보를 당해 “이용자”의 동의 없이 목적 외로 이용하거나 제3자에게 제공할 수 없으며, 이를 위반한 경우에 모든 책임은 “정책자금24”가(이) 집니다. 다만, 다음의 경우에는 예외로 합니다.<br/>
                                          1. 통계작성, 학술연구 또는 시장조사를 위하여 필요한 경우로서 특정 개인을 식별할 수 없는 형태로 제공하는 경우<br/>
                                          2. 도용방지를 위하여 본인확인에 필요한 경우<br/>
                                          3. 약관의 규정 또는 법령에 의하여 필요한 불가피한 사유가 있는 경우<br/>
                                          ④ “정책자금24”가(이) 제2항과 제3항에 의해 “이용자”의 동의를 받아야 하는 경우에는 “개인정보”관리책임자의 신원(소속, 성명 및 전화번호 기타 연락처), 정보의 수집목적 및 이용목적, 제3자에 대한 정보제공관련사항(제공받는 자, 제공목적 및 제공할 정보의 내용)등에 관하여 정보통신망이용촉진 및 정보보호 등에 관한 법률 제22조 제2항이 규정한 사항을 명시하고 고지하여야 합니다.<br/>
                                          ⑤ “이용자”는 언제든지 제3항의 동의를 임의로 철회할 수 있습니다.<br/>
                                          ⑥ “정책자금24”는(은) 개인정보보호를 위하여 관리자를 한정하여 그 수를 최소화하며, 신용카드, 은행계좌 등을 포함한 “이용자”의 “개인정보”의 분실, 도난, 유출, 변조 등으로 인한 “이용자”의 손해에 대하여 책임을 집니다.<br/>
                                          ⑦“정책자금24” 또는 그로부터 “개인정보”를 제공받은 자는 “이용자”가 동의한 범위 내에서 “개인정보”를 사용할 수 있으며, 목적이 달성된 경우에는 당해 “개인정보”를 지체 없이 파기합니다.<br/>
                                          ⑧“정책자금24”는(은) 정보통신망이용촉진 및 정보보호에 관한 법률 등 관계 법령이 정하는 바에 따라 “이용자”의 “개인정보”를 보호하기 위해 노력합니다. “개인정보”의 보호 및 사용에 대해서는 관련법령 및 “정책자금24”의 개인정보보호정책이 적용됩니다.<br/>
                                          <br/>
                                          <br/>
                                          <br/>
                                          제19조 [분쟁의 해결]<br/>
                                          “정책자금24”는(은) 분쟁이 발생하였을 경우에 “이용자”가 제기하는 정당한 의견이나 불만을 반영하여 적절하고 신속한 조치를 취합니다. 다만, 신속한 처리가 곤란한 경우에 “⑦”는(은) “이용자”에게 그 사유와 처리일정을 통보합니다.<br/>
                                      </>
                                      :
                                      <>
                                          수집하는 개인정보 항목<br/>
                                          정책자금24는 회원가입, 상담, 서비스 신청 등을 위해 아래와 같은 개인정보를 수집하고 있습니다.<br/>
                                          1. 필수항목 : 아이디, 이름, 패스워드, 연락처, 이메일<br/>
                                          2. 자동수집항목 : 서비스 이용기록, 접속 로그, 쿠키, 접속 IP 정보<br/>
                                          <br/>
                                          개인정보의 수집 및 이용목적<br/>
                                          정책자금24는 수집한 개인정보를 다음의 목적을 위해 활용합니다.<br/>
                                          1. 서비스 이용에 따른 본인식별, 실명확인, 가입의사 확인<br/>
                                          2. 고지사항 전달, 의사소통 경로 확보, 이벤트 당첨 물품 배송 시 정확한 배송지 정보 확보<br/>
                                          3. 신규 서비스 등 최신정보 개인 맞춤 서비스 제공을 위한 자료<br/>
                                          4. 불량회원 부정 이용 방지 및 비인가 사용 방지<br/>
                                          5. 기타 원활한 양질의 서비스 제공<br/>
                                          <br/>
                                          개인정보의 보유 및 이용기간<br/>
                                          원칙적으로 개인정보의 수집 또는 제공받은 목적 달성 시 지체 없이 파기합니다.<br/>
                                          회원으로서 정책자금24가(이) 제공하는 서비스 이용기간 동안 회원님의 개인정보는 '정책자금24'에서 계속 보유하게 되나 원칙적으로 개인정보의 수집 또는 제공받은 목적 달성 시 지체 없이 파기합니다.<br/>
                                          이용약관 및 미풍양속을 해치는 등 서비스에 물의를 일으키는 경우, 불량사용자에 대해서는 이용자의 의사에 관계없이 강제탈퇴됩니다.<br/>
                                          <br/>
                                          개인정보의 파기절차 및 방법<br/>
                                          정책자금24는 원칙적으로 개인정보 수집 및 이용목적이 달성된 후에는 해당 정보를 지체없이 파기합니다. 파기절차 및 방법은 다음과 같습니다.<br/>
                                          ο 파기절차<br/>
                                          회원님이 회원가입 등을 위해 입력하신 정보는 목적이 달성된 후 별도의 DB로 옮겨져(종이의 경우 별도의 서류함) 내부 방침 및 기타 관련 법령에 의한 정보보호 사유에 따라(보유 및 이용기간 참조) 일정 기간 저장된 후 파기되어집니다. 별도 DB로 옮겨진 개인정보는 법률에 의한 경우가 아니고서는 보유되어지는 이외의 다른 목적으로 이용되지 않습니다.<br/>
                                          ο 파기방법<br/>
                                          - 전자적 파일형태로 저장된 개인정보는 기록을 재생할 수 없는 기술적 방법을 사용하여 삭제합니다.<br/>
                                          <br/>
                                          개인정보<br/>
                                          정책자금24는 이용자의 개인정보를 원칙적으로 외부에 제공하지 않습니다. 다만, 아래의 경우에는 예외로 합니다.<br/>
                                          - 이용자들이 사전에 동의한 경우<br/>
                                          - 법령의 규정에 의거하거나, 수사 목적으로 법령에 정해진 절차와 방법에 따라 수사기관의 요구가 있는 경우<br/>
                                          <br/>
                                          수집한 개인정보의 위탁<br/>
                                          정책자금24는 고객님의 동의없이 고객님의 정보를 외부 업체에 위탁하지 않습니다.<br/>
                                          향후 그러한 필요가 생길 경우, 위탁 대상자와 위탁 업무 내용에 대해 고객님에게 통지하고 필요한 경우 사전 동의를 받도록 하겠습니다.<br/>
                                          <br/>
                                          이용자 및 법정대리인의 권리와 그 행사방법<br/>
                                          이용자 및 법정 대리인은 언제든지 등록되어 있는 자신 혹은 당해 만 14세 미만 아동의 개인정보를 조회하거나 수정할 수 있으며 가입해지를 요청할 수도 있습니다.<br/>
                                          이용자 혹은 만 14세 미만 아동의 개인정보 조회를 위해서는 ‘정보수정’ 을, 가입해지(동의철회)를 위해서는 "회원탈퇴"를 클릭하여 본인 확인 절차를 거치신 후 직접 열람, 정정 또는 탈퇴가 가능합니다.<br/>
                                          혹은 개인정보관리책임자에게 서면, 전화 또는 이메일로 연락하시면 지체없이 조치하겠습니다.<br/>
                                          귀하가 개인정보의 오류에 대한 정정을 요청하신 경우에는 정정을 완료하기 전까지 개인정보를 이용 또는 제공하지 않습니다.<br/>
                                          또한 잘못된 개인정보를 제3자에게 이미 제공한 경우에는 정정 처리결과를 제3자에게 지체없이 통지하여 정정이 이루어지도록 하겠습니다.<br/>
                                          정책자금24는 이용자 혹은 법정 대리인의 요청에 의해 해지 또는 삭제된 개인정보는 정책자금24가(이) 수집하는 개인정보의 보유 및 이용기간"에 명시된 바에 따라 처리하고 그 외의 용도로 열람 또는 이용할 수 없도록 처리하고 있습니다.<br/>
                                          <br/>
                                          개인정보 자동수집 장치의 설치, 운영 및 그 거부에 관한 사항<br/>
                                          정책자금24는 귀하의 정보를 수시로 저장하고 찾아내는 '쿠키(cookie)' 등을 운용합니다.<br/>
                                          쿠키란 기업성장진흥연구소의 웹사이트를 운영하는데 이용되는 서버가 귀하의 브라우저에 보내는 아주 작은 텍스트 파일로서 귀하의 컴퓨터 하드디스크에 저장됩니다.<br/>
                                          정책자금24는 다음과 같은 목적을 위해 쿠키를 사용합니다.<br/>
                                          <br/>
                                          쿠키 등 사용 목적<br/>
                                          - 회원과 비회원의 접속 빈도나 방문 시간 등을 분석, 이용자의 취향과 관심분야를 파악 및 자취 추적, 각종 이벤트 참여 정도 및 방문 회수 파악 등을 통한 타겟 마케팅 및 개인 맞춤 서비스 제공<br/>
                                          <br/>
                                          쿠키 설정 거부 방법<br/>
                                          설정방법 예(인터넷 익스플로어의 경우) : 웹 브라우저 상단의 도구 > 인터넷 옵션 > 개인정보<br/>
                                          단, 귀하께서 쿠키 설치를 거부하였을 경우 서비스 제공에 어려움이 있을 수 있습니다.
                                      </>
                                  }
                              </Col>
                          </Row>
                      </ModalBody>
                  </Modal>
              </div>
              :
              <div style={{ fontFamily: 'regular' }}>
                  <Row style={{ backgroundColor: '#dadada77', minHeight: '350px' }} className="justify-content-center">
                      <Col md="8">
                          <Row style={{ fontSize: '1.2rem', fontWeight: '500', marginTop: '6%' }}>
                              <Col md="auto" onClick={() => {
                                  setDiff('이용약관')
                                  setModalChange(true)
                              }} style={{ cursor: 'pointer' }}>이용약관</Col>
                              <Col md="auto" className="text-muted font-weight-normal">|</Col>
                              <Col md="auto" onClick={() => {
                                  setDiff('개인정보')
                                  setModalChange(true)
                              }} style={{ cursor: 'pointer' }}>개인정보처리방침</Col>
                          </Row>
                          <Row style={{ fontSize: '1.2rem', fontWeight: '750' }} className="mt-5">
                              <Col md="auto">정책자금24</Col>
                          </Row>
                          <Row style={{ fontSize: '1.2rem', fontWeight: '500' }} className="mt-3">
                              <Col md="auto">고객센터: 1670-2375~6</Col>
                          </Row>
                      </Col>
                  </Row>
                  <Modal
                      isOpen={modalChange}
                      toggle={() => setModalChange(false)}
                      className="modal-dialog-centered"
                      style={{ minWidth: '60%' }}
                      fade={false}
                  >
                      <ModalHeader>
                          <h6 className="modal-title">
                              {diff !== '이용약관' ? '개인정보처리방침' : '이용약관'
                              }
                          </h6>
                          <button
                              aria-label="Close"
                              className="close"
                              data-dismiss="modal"
                              type="button"
                              onClick={() => {
                                  setModalChange(false)
                              }}
                          >
                              ×
                          </button>
                      </ModalHeader>
                      <ModalBody>
                          <Row className="mt-3">
                              <Col md="auto">
                                  {diff === '이용약관'
                                      ?
                                          <>
                                          제1장 총칙<br/>
                                          <br/>
                                          제1조 [목적]<br/>
                                          이 약관은 정책자금24 는 온라인으로 제공하는 디지털콘텐츠(이하 “콘텐츠”라고 한다) 및 제반서비스의 이용과 관련하여 정책자금24와 이용자와의 권리, 의무 및 책임사항 등을 규정함을 목적으로 합니다.<br/>
                                          <br/>
                                          제2조 [정의]<br/>
                                          이 약관에서 사용하는 용어의 정의는 다음과 같습니다.<br/>
                                          1. “정책자금24”라 함은 “콘텐츠” 산업과 관련된 경제활동을 영위하는 자로서 콘텐츠 및 컨설팅서비스를 제공하는 자를 말합니다.<br/>
                                          2. “이용자”라 함은 “정책자금24”의 사이트에 접속하여 이 약관에 따라 “정책자금24”가 제공하는 “콘텐츠” 및 제반서비스를 이용하는 회원 및 비회원을 말합니다.<br/>
                                          3. “회원”이라 함은 “정책자금24”와(과) 이용계약을 체결하고 “이용자” 아이디(ID)를 부여받은 “이용자”로서 “정책자금24”의 정보를 지속적으로 제공받으며 “정책자금24”가(이) 제공하는 서비스를 지속적으로 이용할 수 있는 자를 말합니다.<br/>
                                          4. “비회원”이라 함은 “회원”이 아니면서 “정책자금24”가(이) 제공하는 서비스를 이용하는 자를 말합니다.<br/>
                                          5. “콘텐츠”라 함은 정보통신망이용촉진 및 정보보호 등에 관한 법률 제2조 제1항 제1호의 규정에 의한 정보통신망에서 사용되는 부호·문자·음성·음향·이미지 또는 영상 등으로 표현된 자료 또는 정보로서, 그 보존 및 이용에 있어서 효용을 높일 수 있도록 전자적 형태로 제작 또는 처리된 것을 말합니다.<br/>
                                          6. “아이디(ID)”라 함은 “회원”의 식별과 서비스이용을 위하여 “회원”이 정하고 “정책자금24”가(이) 승인하는 문자 또는 숫자의 조합을 말합니다.<br/>
                                          7. “비밀번호(PASSWORD)”라 함은 “회원”이 부여받은 “아이디”와 일치되는 “회원”임을 확인하고 비밀보호를 위해 “회원” 자신이 정한 문자 또는 숫자의 조합을 말합니다.<br/>
                                          <br/>
                                          제3조 [신원정보 등의 제공]<br/>
                                          “정책자금24”는(은) 이 약관의 내용, 상호, 대표자 성명, 영업소 소재지 주소(소비자의 불만을 처리할 수 있는 곳의 주소를 포함), 전화번호, 모사전송번호, 전자우편주소, 사업자등록번호, 통신판매업 신고번호 및 개인정보관리책임자 등을 이용자가 쉽게 알 수 있도록 온라인 서비스초기화면에 게시합니다. 다만, 약관은 이용자가 연결화면을 통하여 볼 수 있도록 할 수 있습니다.<br/>
                                          <br/>
                                          제4조 [약관의 게시 등]<br/>
                                          ① “정책자금24”는(은) 이 약관을 “회원”이 그 전부를 인쇄할 수 있고 거래과정에서 해당 약관의 내용을 확인할 수 있도록 기술적 조치를 취합니다.<br/>
                                          ② “정책자금24”는(은) “이용자”가 “기업성장진흥연구소”와(과) 이 약관의 내용에 관하여 질의 및 응답할 수 있도록 기술적 장치를 설치합니다.<br/>
                                          ③ “정책자금24”는(은) “이용자”가 약관에 동의하기에 앞서 약관에 정하여져 있는 내용 중 청약철회, 환불조건 등과 같은 중요한 내용을 이용자가 쉽게 이해할 수 있도록 별도의 연결화면 또는 팝업화면 등을 제공하여 “이용자”의 확인을 구합니다.<br/>
                                          <br/>
                                          제5조 [약관의 개정 등]<br/>
                                          ① “정책자금24”는(은) 온라인 디지털콘텐츠산업 발전법, 전자상거래 등에서의 소비자보호에 관한 법률, 약관의 규제에 관한 법률 등 관련법을 위배하지 않는 범위에서 이 약관을 개정할 수 있습니다.<br/>
                                          ② “정책자금24”가(이) 약관을 개정할 경우에는 적용일자 및 개정사유를 명시하여 현행약관과 함께 서비스초기화면에 그 적용일자 7일 이전부터 적용일 후 상당한 기간동안 공지하고, 기존회원에게는 개정약관을 전자우편주소로 전송합니다.<br/>
                                          ③ “정책자금24”가(이) 약관을 개정할 경우에는 개정약관 공지 후 개정약관의 적용에 대한 “이용자”의 동의 여부를 확인합니다. “이용자”가 개정약관의 적용에 동의하지 않는 경우 “정책자금24” 또는 “이용자”는 콘텐츠 이용계약을 해지할 수 있습니다. 이때, “정책자금24”는(은) 계약해지로 인하여 “이용자”가 입은 손해를 배상합니다.<br/>
                                          <br/>
                                          제6조 [약관의 해석]<br/>
                                          이 약관에서 정하지 아니한 사항과 이 약관의 해석에 관하여는 온라인 디지털콘텐츠산업 발전법, 전자상거래 등에서의 소비자보호에 관한 법률, 약관의 규제에 관한 법률, 정보통신부장관이 정하는 디지털콘텐츠이용자보호지침, 기타 관계법령 또는 상관례에 따릅니다.<br/>
                                          <br/>
                                          제2장 회원가입<br/>
                                          <br/>
                                          제7조 [회원가입]<br/>
                                          ① 회원가입은 “이용자”가 약관의 내용에 대하여 동의를 하고 회원가입신청을 한 후 “정책자금24”가(이) 이러한 신청에 대하여 승낙함으로써 체결됩니다.<br/>
                                          ② 회원가입신청서에는 다음 사항을 기재해야 합니다. 1호 내지 3호의 사항은 필수사항이며, 그 외의 사항은 선택사항입니다.<br/>
                                          1. “회원”의 성명 또는 인터넷상 개인식별번호<br/>
                                          2. “아이디”와 “비밀번호”<br/>
                                          3. 전자우편주소<br/>
                                          4. 이용하려는 “콘텐츠”의 종류<br/>
                                          5. 기타 “정책자금24”가(이) 필요하다고 인정하는 사항<br/>
                                          ③ “정책자금24”는(은) 상기 “이용자”의 신청에 대하여 회원가입을 승낙함을 원칙으로 합니다. 다만, “정책자금24”는(은) 다음 각 호에 해당하는 신청에 대하여는 승낙을 하지 않을 수 있습니다.<br/>
                                          1. 가입신청자가 이 약관에 의하여 이전에 회원자격을 상실한 적이 있는 경우<br/>
                                          2. 실명이 아니거나 타인의 명의를 이용한 경우<br/>
                                          3. 허위의 정보를 기재하거나, 기업성장진흥연구소가(이) 제시하는 내용을 기재하지 않은 경우<br/>
                                          4. 이용자의 귀책사유로 인하여 승인이 불가능하거나 기타 규정한 제반 사항을 위반하며 신청하는 경우<br/>
                                          ④ “정책자금24”는(은) 서비스 관련 설비의 여유가 없거나, 기술상 또는 업무상 문제가 있는 경우에는 승낙을 유보할 수 있습니다.<br/>
                                          ⑤ 제3항과 제4항에 따라 회원가입신청의 승낙을 하지 아니하거나 유보한 경우, “정책자금24”는(은) 이를 신청자에게 알려야 합니다. “기업성장진흥연구소”의 귀책사유 없이 신청자에게 통지할 수 없는 경우에는 예외로 합니다.<br/>
                                          ⑥ 회원가입계약의 성립 시기는 “정책자금24”의 승낙이 “이용자”에게 도달한 시점으로 합니다.<br/>
                                          <br/>
                                          제8조 [미성년자의 회원가입에 관한 특칙]<br/>
                                          ① 만 14세 미만의 “이용자”는 개인정보의 수집 및 이용목적에 대하여 충분히 숙지하고 부모 등 법정대리인의 동의를 얻은 후에 회원가입을 신청하고 본인의 개인정보를 제공하여야 합니다.<br/>
                                          ② 기업성장진흥연구소는(은) 부모 등 법정대리인의 동의에 대한 확인절차를 거치지 않은 14세 미만 이용자에 대하여는 가입을 취소 또는 불허합니다.<br/>
                                          ③ 만 14세 미만 “이용자”의 부모 등 법정대리인은 아동에 대한 개인정보의 열람, 정정, 갱신을 요청하거나 회원가입에 대한 동의를 철회할 수 있으며, 이러한 경우에 “정책자금24”는(은) 지체 없이 필요한 조치를 취해야 합니다.<br/>
                                          <br/>
                                          제9조 [회원정보의 변경]<br/>
                                          ① “회원”은 개인정보관리화면을 통하여 언제든지 자신의 개인정보를 열람하고 수정할 수 있습니다.<br/>
                                          ② “회원”은 회원가입신청 시 기재한 사항이 변경되었을 경우 온라인으로 수정을 하거나 전자우편 기타 방법으로 “정책자금24”에 대하여 그 변경사항을 알려야 합니다.<br/>
                                          ③ 제2항의 변경사항을 “기업성장진흥연구소”에 알리지 않아 발생한 불이익에 대하여 “정책자금24”는(은) 책임지지 않습니다.<br/>
                                          <br/>
                                          제10조 [“회원”의 “아이디” 및 “비밀번호”의 관리에 대한 의무]<br/>
                                          ① “회원”의 “아이디”와 “비밀번호”에 관한 관리책임은 “회원”에게 있으며, 이를 제3자가 이용하도록 하여서는 안 됩니다.<br/>
                                          ② “회원”은 “아이디” 및 “비밀번호”가 도용되거나 제3자에 의해 사용되고 있음을 인지한 경우에는 이를 즉시 “정책자금24”에 통지하고 “정책자금24”의 안내에 따라야 합니다.<br/>
                                          ③ 제2항의 경우에 해당 “회원”이 “정책자금24”에 그 사실을 통지하지 않거나, 통지한 경우에도 “정책자금24”의 안내에 따르지 않아 발생한 불이익에 대하여 “정책자금24”는(은) 책임지지 않습니다.<br/>
                                          <br/>
                                          제11조 [“회원”에 대한 통지]<br/>
                                          ① “정책자금24”가(이) “회원”에 대한 통지를 하는 경우 “회원”이 지정한 전자우편주소로 할 수 있습니다.<br/>
                                          ② “정책자금24”는(은) “회원” 전체에 대한 통지의 경우 7일 이상 “정책자금24”의 게시판에 게시함으로써 제1항의 통지에 갈음할 수 있습니다. 다만, “회원” 본인의 거래와 관련하여 중대한 영향을 미치는 사항에 대하여는 제1항의 통지를 합니다.<br/>
                                          <br/>
                                          제12조 [회원탈퇴 및 자격 상실 등]<br/>
                                          ① “회원”은 “정책자금24”에 언제든지 탈퇴를 요청할 수 있으며 “정책자금24”는(은) 즉시 회원탈퇴를 처리합니다.<br/>
                                          ② “회원”이 다음 각호의 사유에 해당하는 경우, “정책자금24”는(은) 회원자격을 제한 및 정지시킬 수 있습니다.<br/>
                                          1. 가입신청 시에 허위내용을 등록한 경우<br/>
                                          2. “기업성장진흥연구소”의 서비스이용대금, 기타 “정책자금24”의 서비스이용에 관련하여 회원이 부담하는 채무를 기일에 이행하지 않는 경우<br/>
                                          3. 다른 사람의 “정책자금24”의 서비스이용을 방해하거나 그 정보를 도용하는 등 전자상거래 질서를 위협하는 경우<br/>
                                          4. “정책자금24”을(를) 이용하여 법령 또는 이 약관이 금지하거나 공서양속에 반하는 행위를 하는 경우<br/>
                                          ③“정책자금24”가(이) 회원자격을 제한·정지시킨 후, 동일한 행위가 2회 이상 반복되거나 30일 이내에 그 사유가 시정되지 아니하는 경우<br/>
                                          “정책자금24”는(은) 회원자격을 상실시킬 수 있습니다.<br/>
                                          ④“정책자금24”가(이) 회원자격을 상실시키는 경우에는 회원등록을 말소합니다. 이 경우 “회원”에게 이를 통지하고, 회원등록 말소 전에 최소한 30일 이상의 기간을 정하여 소명할 기회를 부여합니다.<br/>
                                          <br/>
                                          <br/>
                                          <br/>
                                          제13조 [이용계약의 성립 등]<br/>
                                          ① “이용자”는 “정책자금24”가(이) 제공하는 다음 또는 이와 유사한 절차에 의하여 이용신청을 합니다. “정책자금24”는(은) 계약 체결 전에 각 호의 사항에 관하여 “이용자”가 정확하게 이해하고 실수 또는 착오 없이 거래할 수 있도록 정보를 제공합니다.<br/>
                                          1. “콘텐츠” 목록의 열람 및 선택<br/>
                                          2. 성명, 주소, 전화번호(또는 이동전화번호), 전자우편주소 등의 입력<br/>
                                          3. 약관내용, 청약철회가 불가능한 “콘텐츠”에 대해 “정책자금24”가(이) 취한 조치에 관련한 내용에 대한 확인<br/>
                                          4. 이 약관에 동의하고 위 제3호의 사항을 확인하거나 거부하는 표시(예, 마우스 클릭)<br/>
                                          5. “콘텐츠”의 이용신청에 관한 확인 또는 “정책자금24”의 확인에 대한 동의<br/>
                                          ② “정책자금24”의 승낙이 제16조 제1항의 수신확인통지형태로 “이용자”에게 도달한 시점에 계약이 성립한 것으로 봅니다.<br/>
                                          ③ “정책자금24”의 승낙의 의사표시에는 “이용자”의 이용신청에 대한 확인 및 서비스제공 가능여부, 이용신청의 정정·취소 등에 관한 정보 등을 포함합니다.<br/>
                                          <br/>
                                          제14조 [미성년자 이용계약에 관한 특칙]<br/>
                                          “기업성장진흥연구소”는(은) 만 20세 미만의 미성년이용자가 유료서비스를 이용하고자 하는 경우에 부모 등 법정 대리인의 동의를 얻거나, 계약체결 후 추인을 얻지 않으면 미성년자 본인 또는 법정대리인이 그 계약을 취소할 수 있다는 내용을 계약체결 전에 고지하는 조치를 취합니다.<br/>
                                          <br/>
                                          제15조 [수신확인통지·이용신청 변경 및 취소]<br/>
                                          ① “정책자금24”는(은) “이용자”의 이용신청이 있는 경우 “이용자”에게 수신확인통지를 합니다.<br/>
                                          ② 수신확인통지를 받은 “이용자”는 의사표시의 불일치 등이 있는 경우에는 수신확인통지를 받은 후 즉시 이용신청 변경 및 취소를 요청할 수 있고, “정책자금24”는(은) 서비스제공 전에 “이용자”의 요청이 있는 경우에는 지체 없이 그 요청에 따라 처리하여야 합니다. 다만, 이미 대금을 지불한 경우에는 청약철회 등에 관한 제27조의 규정에 따릅니다.<br/>
                                          <br/>
                                          제16조 [“정책자금24”의 의무]<br/>
                                          ① “정책자금24”는(은) 법령과 이 약관이 정하는 권리의 행사와 의무의 이행을 신의에 좇아 성실하게 하여야 합니다.<br/>
                                          ② “정책자금24”는(은) “이용자”가 안전하게 “콘텐츠”를 이용할 수 있도록 개인정보(신용정보 포함)보호를 위해 보안시스템을 갖추어야 하며 개인정보보호정책을 공시하고 준수합니다.<br/>
                                          ③ “정책자금24”는(은) “이용자”가 콘텐츠이용 및 그 대금내역을 수시로 확인할 수 있도록 조치합니다.<br/>
                                          ④ “정책자금24”는(은) 콘텐츠이용과 관련하여 “이용자”로부터 제기된 의견이나 불만이 정당하다고 인정할 경우에는 이를 지체없이 처리합니다. 이용자가 제기한 의견이나 불만사항에 대해서는 게시판을 활용하거나 전자우편 등을 통하여 그 처리과정 및 결과를 전달합니다.<br/>
                                          ⑤ “정책자금24”는(은) 이 약관에서 정한 의무 위반으로 인하여 “이용자”가 입은 손해를 배상합니다.<br/>
                                          <br/>
                                          제17조 [“이용자”의 의무]<br/>
                                          ① “이용자”는 다음 행위를 하여서는 안 됩니다.<br/>
                                          1. 신청 또는 변경 시 허위내용의 기재<br/>
                                          2. 타인의 정보도용<br/>
                                          3. “정책자금24”에 게시된 정보의 변경<br/>
                                          4. “정책자금24”가(이) 금지한 정보(컴퓨터 프로그램 등)의 송신 또는 게시<br/>
                                          5. “정책자금24”와(과) 기타 제3자의 저작권 등 지적재산권에 대한 침해<br/>
                                          6. “정책자금24” 및 기타 제3자의 명예를 손상시키거나 업무를 방해하는 행위<br/>
                                          7. 외설 또는 폭력적인 말이나 글, 화상, 음향, 기타 공서양속에 반하는 정보를 “정책자금24”의 사이트에 공개 또는 게시하는 행위<br/>
                                          8. 기타 불법적이거나 부당한 행위<br/>
                                          ② “이용자”는 관계법령, 이 약관의 규정, 이용안내 및 “콘텐츠”와 관련하여 공지한 주의사항, “정책자금24”가(이) 통지하는 사항 등을 준수하여야 하며, 기타 “정책자금24”의 업무에 방해되는 행위를 하여서는 안 됩니다.<br/>
                                          <br/>
                                          <br/>
                                          제18조 [개인정보보호]<br/>
                                          ① “정책자금24”는(은) 제7조 제2항의 신청서기재사항 이외에 “이용자”의 콘텐츠이용에 필요한 최소한의 정보를 수집할 수 있습니다. 이를 위해 “정책자금24”가(이) 문의한 사항에 관해 “이용자”는 진실한 내용을 성실하게 고지하여야 합니다.<br/>
                                          ② “정책자금24”가(이) “이용자”의 개인 식별이 가능한 “개인정보”를 수집하는 때에는 당해 “이용자”의 동의를 받습니다.<br/>
                                          ③ “정책자금24”는(은) “이용자”가 이용신청 등에서 제공한 정보와 제1항에 의하여 수집한 정보를 당해 “이용자”의 동의 없이 목적 외로 이용하거나 제3자에게 제공할 수 없으며, 이를 위반한 경우에 모든 책임은 “정책자금24”가(이) 집니다. 다만, 다음의 경우에는 예외로 합니다.<br/>
                                          1. 통계작성, 학술연구 또는 시장조사를 위하여 필요한 경우로서 특정 개인을 식별할 수 없는 형태로 제공하는 경우<br/>
                                          2. 도용방지를 위하여 본인확인에 필요한 경우<br/>
                                          3. 약관의 규정 또는 법령에 의하여 필요한 불가피한 사유가 있는 경우<br/>
                                          ④ “정책자금24”가(이) 제2항과 제3항에 의해 “이용자”의 동의를 받아야 하는 경우에는 “개인정보”관리책임자의 신원(소속, 성명 및 전화번호 기타 연락처), 정보의 수집목적 및 이용목적, 제3자에 대한 정보제공관련사항(제공받는 자, 제공목적 및 제공할 정보의 내용)등에 관하여 정보통신망이용촉진 및 정보보호 등에 관한 법률 제22조 제2항이 규정한 사항을 명시하고 고지하여야 합니다.<br/>
                                          ⑤ “이용자”는 언제든지 제3항의 동의를 임의로 철회할 수 있습니다.<br/>
                                          ⑥ “정책자금24”는(은) 개인정보보호를 위하여 관리자를 한정하여 그 수를 최소화하며, 신용카드, 은행계좌 등을 포함한 “이용자”의 “개인정보”의 분실, 도난, 유출, 변조 등으로 인한 “이용자”의 손해에 대하여 책임을 집니다.<br/>
                                          ⑦“정책자금24” 또는 그로부터 “개인정보”를 제공받은 자는 “이용자”가 동의한 범위 내에서 “개인정보”를 사용할 수 있으며, 목적이 달성된 경우에는 당해 “개인정보”를 지체 없이 파기합니다.<br/>
                                          ⑧“정책자금24”는(은) 정보통신망이용촉진 및 정보보호에 관한 법률 등 관계 법령이 정하는 바에 따라 “이용자”의 “개인정보”를 보호하기 위해 노력합니다. “개인정보”의 보호 및 사용에 대해서는 관련법령 및 “정책자금24”의 개인정보보호정책이 적용됩니다.<br/>
                                          <br/>
                                          <br/>
                                          <br/>
                                          제19조 [분쟁의 해결]<br/>
                                          “정책자금24”는(은) 분쟁이 발생하였을 경우에 “이용자”가 제기하는 정당한 의견이나 불만을 반영하여 적절하고 신속한 조치를 취합니다. 다만, 신속한 처리가 곤란한 경우에 “⑦”는(은) “이용자”에게 그 사유와 처리일정을 통보합니다.<br/>
                                          </>
                                      :
                                          <>
                                          수집하는 개인정보 항목<br/>
                                          정책자금24는 회원가입, 상담, 서비스 신청 등을 위해 아래와 같은 개인정보를 수집하고 있습니다.<br/>
                                          1. 필수항목 : 아이디, 이름, 패스워드, 연락처, 이메일<br/>
                                          2. 자동수집항목 : 서비스 이용기록, 접속 로그, 쿠키, 접속 IP 정보<br/>
                                          <br/>
                                          개인정보의 수집 및 이용목적<br/>
                                          정책자금24는 수집한 개인정보를 다음의 목적을 위해 활용합니다.<br/>
                                          1. 서비스 이용에 따른 본인식별, 실명확인, 가입의사 확인<br/>
                                          2. 고지사항 전달, 의사소통 경로 확보, 이벤트 당첨 물품 배송 시 정확한 배송지 정보 확보<br/>
                                          3. 신규 서비스 등 최신정보 개인 맞춤 서비스 제공을 위한 자료<br/>
                                          4. 불량회원 부정 이용 방지 및 비인가 사용 방지<br/>
                                          5. 기타 원활한 양질의 서비스 제공<br/>
                                          <br/>
                                          개인정보의 보유 및 이용기간<br/>
                                          원칙적으로 개인정보의 수집 또는 제공받은 목적 달성 시 지체 없이 파기합니다.<br/>
                                          회원으로서 정책자금24가(이) 제공하는 서비스 이용기간 동안 회원님의 개인정보는 '정책자금24'에서 계속 보유하게 되나 원칙적으로 개인정보의 수집 또는 제공받은 목적 달성 시 지체 없이 파기합니다.<br/>
                                          이용약관 및 미풍양속을 해치는 등 서비스에 물의를 일으키는 경우, 불량사용자에 대해서는 이용자의 의사에 관계없이 강제탈퇴됩니다.<br/>
                                          <br/>
                                          개인정보의 파기절차 및 방법<br/>
                                          정책자금24는 원칙적으로 개인정보 수집 및 이용목적이 달성된 후에는 해당 정보를 지체없이 파기합니다. 파기절차 및 방법은 다음과 같습니다.<br/>
                                          ο 파기절차<br/>
                                          회원님이 회원가입 등을 위해 입력하신 정보는 목적이 달성된 후 별도의 DB로 옮겨져(종이의 경우 별도의 서류함) 내부 방침 및 기타 관련 법령에 의한 정보보호 사유에 따라(보유 및 이용기간 참조) 일정 기간 저장된 후 파기되어집니다. 별도 DB로 옮겨진 개인정보는 법률에 의한 경우가 아니고서는 보유되어지는 이외의 다른 목적으로 이용되지 않습니다.<br/>
                                          ο 파기방법<br/>
                                          - 전자적 파일형태로 저장된 개인정보는 기록을 재생할 수 없는 기술적 방법을 사용하여 삭제합니다.<br/>
                                          <br/>
                                          개인정보<br/>
                                          정책자금24는 이용자의 개인정보를 원칙적으로 외부에 제공하지 않습니다. 다만, 아래의 경우에는 예외로 합니다.<br/>
                                          - 이용자들이 사전에 동의한 경우<br/>
                                          - 법령의 규정에 의거하거나, 수사 목적으로 법령에 정해진 절차와 방법에 따라 수사기관의 요구가 있는 경우<br/>
                                          <br/>
                                          수집한 개인정보의 위탁<br/>
                                          정책자금24는 고객님의 동의없이 고객님의 정보를 외부 업체에 위탁하지 않습니다.<br/>
                                          향후 그러한 필요가 생길 경우, 위탁 대상자와 위탁 업무 내용에 대해 고객님에게 통지하고 필요한 경우 사전 동의를 받도록 하겠습니다.<br/>
                                          <br/>
                                          이용자 및 법정대리인의 권리와 그 행사방법<br/>
                                          이용자 및 법정 대리인은 언제든지 등록되어 있는 자신 혹은 당해 만 14세 미만 아동의 개인정보를 조회하거나 수정할 수 있으며 가입해지를 요청할 수도 있습니다.<br/>
                                          이용자 혹은 만 14세 미만 아동의 개인정보 조회를 위해서는 ‘정보수정’ 을, 가입해지(동의철회)를 위해서는 "회원탈퇴"를 클릭하여 본인 확인 절차를 거치신 후 직접 열람, 정정 또는 탈퇴가 가능합니다.<br/>
                                          혹은 개인정보관리책임자에게 서면, 전화 또는 이메일로 연락하시면 지체없이 조치하겠습니다.<br/>
                                          귀하가 개인정보의 오류에 대한 정정을 요청하신 경우에는 정정을 완료하기 전까지 개인정보를 이용 또는 제공하지 않습니다.<br/>
                                          또한 잘못된 개인정보를 제3자에게 이미 제공한 경우에는 정정 처리결과를 제3자에게 지체없이 통지하여 정정이 이루어지도록 하겠습니다.<br/>
                                          정책자금24는 이용자 혹은 법정 대리인의 요청에 의해 해지 또는 삭제된 개인정보는 정책자금24가(이) 수집하는 개인정보의 보유 및 이용기간"에 명시된 바에 따라 처리하고 그 외의 용도로 열람 또는 이용할 수 없도록 처리하고 있습니다.<br/>
                                          <br/>
                                          개인정보 자동수집 장치의 설치, 운영 및 그 거부에 관한 사항<br/>
                                          정책자금24는 귀하의 정보를 수시로 저장하고 찾아내는 '쿠키(cookie)' 등을 운용합니다.<br/>
                                          쿠키란 기업성장진흥연구소의 웹사이트를 운영하는데 이용되는 서버가 귀하의 브라우저에 보내는 아주 작은 텍스트 파일로서 귀하의 컴퓨터 하드디스크에 저장됩니다.<br/>
                                          정책자금24는 다음과 같은 목적을 위해 쿠키를 사용합니다.<br/>
                                          <br/>
                                          쿠키 등 사용 목적<br/>
                                          - 회원과 비회원의 접속 빈도나 방문 시간 등을 분석, 이용자의 취향과 관심분야를 파악 및 자취 추적, 각종 이벤트 참여 정도 및 방문 회수 파악 등을 통한 타겟 마케팅 및 개인 맞춤 서비스 제공<br/>
                                          <br/>
                                          쿠키 설정 거부 방법<br/>
                                          설정방법 예(인터넷 익스플로어의 경우) : 웹 브라우저 상단의 도구 > 인터넷 옵션 > 개인정보<br/>
                                          단, 귀하께서 쿠키 설치를 거부하였을 경우 서비스 제공에 어려움이 있을 수 있습니다.
                                      </>
                                  }
                              </Col>
                          </Row>
                      </ModalBody>
                  </Modal>
              </div>
          }
      </>
  );
}

export default AdminFooter;
