import {atom} from "recoil";

export const categoryList = [
    {
        name: '전체',
    },
    {
        name: '창업',
    },
    {
        name: '기술',
    },
    {
        name: '금융',
    },
    {
        name: '수출',
    },
    {
        name: '경영',
    },
    {
        name: '내수',
    },
    {
        name: '인력',
    },
    {
        name: '소상공인',
    }
]

export const filePreviewState = atom({
    key: 'categoryData',
    default: '전체',
});

export const projectIdState = atom({
    key: 'projectIdState',
    default: null,
});