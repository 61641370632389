import React from "react";

// reactstrap components
// import {
// } from "reactstrap";
// core components
import IndexHeader from "components/Headers/IndexHeader.js";
import AdminFooter from "../../../components/Footers/AdminFooter";
import Reservation from "./Reservation";

// sections for this page

function HomeIndex() {

    return (
        <>
            <IndexHeader />
            {/* 홈 */}
            <Reservation/>
            <AdminFooter/>
        </>
    );
}

export default HomeIndex;
