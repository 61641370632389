import axios from 'axios';
import api from "../../../../config/api.routes";
import authHeader from "../../../../services/auth-header";


const login = (loginId, password) => {
  return axios
    .post(api().LOGIN, {
      loginId,
      password,
    })
    .then((res) => {
      if (res.data.statusCode === 200) {
        if (res.data.token) {
          localStorage.setItem("user", JSON.stringify(res.data));
        }
      }
      return res.data;
    });
};

const logout = () => {
  localStorage.removeItem("user");
};

const getCurrentUser = () => {
  return JSON.parse(localStorage.getItem("user"));
};

const loginIdCheck = (loginId) => {
  let url = api().apiURL + `/v1/auth/existLoginId?loginId=${loginId}`;
  console.log('인증받기',url);
  return new Promise((resolve, reject) => {
    axios.get(url, { headers: authHeader() })
        .then(res => res.data)
        .then(res => resolve(res))
        .catch(e => reject(e));
  });
};

const sendCode = (tel, diff) => {
  let url = api().apiURL + `/v1/auth/certificationNumberSMS?tel=${tel}&diff=${diff}`;
  console.log('코드전송',url);
  return new Promise((resolve, reject) => {
    axios.get(url, { headers: authHeader() })
        .then(res => res.data)
        .then(res => resolve(res))
        .catch(e => reject(e));
  });
};

const reservationSuccess = (tel) => {
  let url = api().apiURL + `/v1/auth/reservationSuccess?tel=${tel}`;
  console.log('코드전송',url);
  return new Promise((resolve, reject) => {
    axios.get(url, { headers: authHeader() })
        .then(res => res.data)
        .then(res => resolve(res))
        .catch(e => reject(e));
  });
};

const confirm = (tel, confirm) => {
  let url = api().apiURL + `/v1/auth/confirm?tel=${tel}&confirm=${confirm}`;
  console.log('코드전송',url);
  return new Promise((resolve, reject) => {
    axios.get(url, { headers: authHeader() })
        .then(res => res.data)
        .then(res => resolve(res))
        .catch(e => reject(e));
  });
};

const register = (data) => {
  let url = api().apiURL + `/v1/auth/join?`;
  console.log('유저등록',url,data);
  return new Promise((resolve, reject) => {
    axios.post(url, data, { headers: authHeader() })
        .then(res => res.data)
        .then(res => resolve(res))
        .catch(e => reject(e));
  });
};

const idCheck = (loginId) => {
  let url = api().apiURL + `/v1/auth/existLoginId?loginId=${loginId}`;
  console.log('중복체크',url);
  return new Promise((resolve, reject) => {
    axios.get(url, { headers: authHeader() })
        .then(res => res.data)
        .then(res => resolve(res))
        .catch(e => reject(e));
  });
};

const authService = {
  login,
  logout,
  getCurrentUser,
  loginIdCheck,
  sendCode,
  reservationSuccess,
  confirm,
  register,
  idCheck,
}

export default authService;
