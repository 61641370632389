import IndexHeader from "../../../components/Headers/IndexHeader";
import React, {useEffect, useState} from "react";
import AdminFooter from "../../../components/Footers/AdminFooter";
import {isMobile} from "react-device-detect";
import {Button, Col, Row} from "reactstrap";
import {nextBlue, searchDetailInfo, searchLogo} from "../../../assets/images";
import {projectIdState} from "./data/data";
import {useHistory} from "react-router-dom";
import {useRecoilState} from "recoil";
import searchService from "./service/search.service";
import {searchDetailLogo} from "../../../assets/imagesM";
import styled from "styled-components";

const CheckBox = styled.div`
  line-height: 60px;
  width: 100%;
  color: white;
  text-align: center;
  background-color: #5479f7;
  border-color: #5479f7;
  border-radius: 60px;
  font-size: 1.5rem;
  font-weight: bold;
`

function SearchDetail({ match }) {
    const { id } = match.params
    console.log(match.params)
    const history = useHistory()
    const [data, setData] = useState();
    const [projectId, setProjectId] = useRecoilState(projectIdState);

    const fetchData = async () => {
        const res = await searchService.detail(id);
        console.log('상세', res);
        if (res.statusCode === 200) {
            setData(res.data);
        }
    };

    useEffect( () => {
        fetchData();
    }, []);

    return (
        <>
            <IndexHeader />
            <>
                {isMobile
                    ?
                    <div style={{ fontFamily: 'regular' }}>
                        <Col xs="12" style={{ margin: '0 auto', padding: '5% 10%' }}>
                            <Row>
                                <Col xs="12">
                                    <div
                                        style={{ textAlign: 'center', fontSize: '2rem', fontWeight: '900', margin: '15% 0' }}
                                    >
                                        지원사업 조회
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs="12">
                                    <img src={searchDetailLogo} />
                                </Col>
                            </Row>
                            <Row style={{ margin: '0' }}>
                                <Col style={{ borderTop: '2px solid gray', marginTop: '8%' }}/>
                            </Row>
                            <Row>
                                <Col xs="12">
                                    <div
                                        style={{ textAlign: 'left', fontSize: '1.5rem', fontWeight: '600', margin: '10% 0 5% 0' }}
                                    >
                                        [{data?.category}] {data?.title}
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col style={{ backgroundColor: '#f4f9ff' }}>
                                    <div className="px-2 py-4" style={{ fontSize: '1.2rem', fontWeight: '600' }}>
                                        [{data?.country}]<br/> {data?.term}
                                    </div>
                                </Col>
                            </Row>
                            <Row className="pl-1 mt-5">
                                <Col style={{ fontWeight: '700' }}>
                                    사업수행기관
                                </Col>
                            </Row>
                            <Row className="pl-1 mt-2 mb-4">
                                <Col style={{ fontFamily: "KoPub바탕체 Light", whiteSpace: 'pre-line' }}>
                                    <h5>{data?.from}</h5>
                                </Col>
                            </Row>
                            <Row className="pl-1 mt-2">
                                <Col style={{ fontWeight: '700' }}>
                                    사업개요
                                </Col>
                            </Row>
                            <Row className="pl-1 mt-2 mb-4">
                                <Col style={{ fontFamily: "KoPub바탕체 Light", whiteSpace: 'pre-line' }}>
                                    <h5>{data?.intro}</h5>
                                </Col>
                            </Row>
                            {/*<Row className="pl-1 mt-2">*/}
                            {/*    <Col style={{ fontWeight: '700' }}>*/}
                            {/*        사업신청 방법*/}
                            {/*    </Col>*/}
                            {/*</Row>*/}
                            {/*<Row className="pl-1 mt-2">*/}
                            {/*    <Col style={{ fontFamily: "KoPub바탕체 Light", whiteSpace: 'pre-line' }}>*/}
                            {/*        <h5>{data?.way}</h5>*/}
                            {/*    </Col>*/}
                            {/*</Row>*/}
                            {/*<Row className="pl-1 mb-4 text-center">*/}
                            {/*    {data?.link &&*/}
                            {/*        <Col style={{ fontFamily: "KoPub바탕체 Light", whiteSpace: 'pre-line' }}>*/}
                            {/*            <Button*/}
                            {/*                style={{*/}
                            {/*                    // width: '20%',*/}
                            {/*                    backgroundColor: '#5479f7',*/}
                            {/*                    borderRadius: '50px',*/}
                            {/*                    fontSize: '1.5rem',*/}
                            {/*                    fontWeight: '800',*/}
                            {/*                    lineHeight: '30px'*/}
                            {/*                }}*/}
                            {/*                onClick={(e) => {*/}
                            {/*                    window.location.href = data?.link*/}
                            {/*                }}*/}
                            {/*            >*/}
                            {/*                온라인신청 바로가기*/}
                            {/*            </Button>*/}
                            {/*        </Col>*/}
                            {/*    }*/}
                            {/*</Row>*/}
                            {/*<Row className="pl-1 mt-2">*/}
                            {/*    <Col style={{ fontWeight: '700' }}>*/}
                            {/*        문의처*/}
                            {/*    </Col>*/}
                            {/*</Row>*/}
                            {/*<Row className="pl-1 mt-2 mb-5">*/}
                            {/*    <Col style={{ fontFamily: "KoPub바탕체 Light", whiteSpace: 'pre-line' }}>*/}
                            {/*        <h5>{data?.tel}</h5>*/}
                            {/*    </Col>*/}
                            {/*</Row>*/}
                            <Row className="my-5 text-center">
                                <Col>
                                    <CheckBox
                                        onClick={(e) => {
                                            setProjectId(data?.id)
                                            history.push('/reservation')
                                        }}
                                    >
                                        사업신청 및 예약
                                    </CheckBox>
                                </Col>
                            </Row>
                        </Col>
                    </div>
                    :
                    <div style={{ fontFamily: 'regular' }}>
                        <Col md="8" style={{ margin: '0 auto', backgroundColor: 'white' }} >
                            <Row>
                                <Col md="2">
                                    <Col className="border p-1 mt-4" style={{ borderRadius: '15px' }}>
                                        <Col className="border-bottom">
                                            <p
                                                style={{ fontSize: '1rem', color: '#5479f7', fontWeight: 'bold' }}
                                                className="my-2"
                                            >
                                                지원사업 조회
                                                <span className="ml-2">
                                            <img src={nextBlue} style={{ width: '8%' }}/>
                                        </span>
                                            </p>
                                        </Col>
                                        <Col>
                                            <p
                                                style={{ fontSize: '0.8rem', color: '#5c5c5c', fontWeight: 'bold' }}
                                                className="my-2"
                                            >
                                                지원사업 조회
                                            </p>
                                        </Col>
                                    </Col>
                                </Col>
                                <Col md="10" className="mt-3">
                                    <Row className="pl-1 mt-2 mb-5">
                                        <Col>
                                            <img src={searchDetailInfo} style={{ width: '22%' }}/>
                                        </Col>
                                    </Row>
                                    <Row className="pl-1 mt-2 mb-5">
                                        <Col>
                                            <img src={searchLogo}/>
                                        </Col>
                                    </Row>
                                    <Row className="pl-1 mt-2 mb-3">
                                        <Col>
                                            <div style={{ fontSize: '2rem', fontWeight: '700', letterSpacing: '0.2rem' }}>
                                                [{data?.category}] {data?.title}
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row className="pl-1 mb-5">
                                        <Col style={{ backgroundColor: '#f4f9ff' }}>
                                            <div className="px-2 py-4" style={{ fontSize: '1.2rem', fontWeight: '600' }}>
                                                [{data?.country}] {data?.term}
                                            </div>
                                        </Col>
                                    </Row>
                                    {data?.detail
                                        ?
                                            <Row className="pl-1 mt-2 mb-5">
                                                <Col>
                                                    <div dangerouslySetInnerHTML={{ __html: data?.detail }} />
                                                </Col>
                                            </Row>
                                        :
                                            <>
                                                <Row className="pl-1 mt-2">
                                                    <Col style={{ fontWeight: '700' }}>
                                                        사업수행기관
                                                    </Col>
                                                </Row>
                                                <Row className="pl-1 mt-2 mb-5">
                                                    <Col style={{ fontFamily: "KoPub바탕체 Light", whiteSpace: 'pre-line' }}>
                                                        <h5>{data?.from}</h5>
                                                    </Col>
                                                </Row>
                                                <Row className="pl-1 mt-2">
                                                    <Col style={{ fontWeight: '700' }}>
                                                        사업개요
                                                    </Col>
                                                </Row>
                                                <Row className="pl-1 mt-2 mb-5">
                                                    <Col style={{ fontFamily: "KoPub바탕체 Light", whiteSpace: 'pre-line' }}>
                                                        <h5>{data?.intro}</h5>
                                                    </Col>
                                                </Row>
                                                {/*<Row className="pl-1 mt-2">*/}
                                                {/*    <Col style={{ fontWeight: '700' }}>*/}
                                                {/*        사업신청 방법*/}
                                                {/*    </Col>*/}
                                                {/*</Row>*/}
                                                {/*<Row className="pl-1 mt-2">*/}
                                                {/*    <Col style={{ fontFamily: "KoPub바탕체 Light", whiteSpace: 'pre-line' }}>*/}
                                                {/*        <h5>{data?.way}</h5>*/}
                                                {/*    </Col>*/}
                                                {/*</Row>*/}
                                                {/*<Row className="pl-1 mb-5">*/}
                                                {/*    {data?.link &&*/}
                                                {/*        <Col style={{ fontFamily: "KoPub바탕체 Light", whiteSpace: 'pre-line' }}>*/}
                                                {/*            <Button*/}
                                                {/*                style={{*/}
                                                {/*                    width: '20%',*/}
                                                {/*                    backgroundColor: '#5479f7',*/}
                                                {/*                    borderRadius: '50px',*/}
                                                {/*                    fontSize: '1.5rem',*/}
                                                {/*                    fontWeight: '800',*/}
                                                {/*                    lineHeight: '30px'*/}
                                                {/*                }}*/}
                                                {/*                onClick={(e) => {*/}
                                                {/*                    window.location.href = data?.link*/}
                                                {/*                }}*/}
                                                {/*            >*/}
                                                {/*                온라인신청 바로가기*/}
                                                {/*            </Button>*/}
                                                {/*        </Col>*/}
                                                {/*    }*/}
                                                {/*</Row>*/}
                                                {/*<Row className="pl-1 mt-2">*/}
                                                {/*    <Col style={{ fontWeight: '700' }}>*/}
                                                {/*        문의처*/}
                                                {/*    </Col>*/}
                                                {/*</Row>*/}
                                                {/*<Row className="pl-1 mt-2 mb-5">*/}
                                                {/*    <Col style={{ fontFamily: "KoPub바탕체 Light", whiteSpace: 'pre-line' }}>*/}
                                                {/*        <h5>{data?.tel}</h5>*/}
                                                {/*    </Col>*/}
                                                {/*</Row>*/}
                                            </>
                                    }
                                    <Row className="pl-1 mt-2 mb-5 text-center">
                                        <Col>
                                            <Button
                                                style={{
                                                    width: '30%',
                                                    backgroundColor: '#5479f7',
                                                    borderRadius: '50px',
                                                    fontSize: '1.5rem',
                                                    fontWeight: '800',
                                                    margin: '5% 0',
                                                    lineHeight: '50px'
                                                }}
                                                onClick={(e) => {
                                                    setProjectId(data?.id)
                                                    history.push('/reservation')
                                                }}
                                            >
                                                사업신청 및 예약
                                            </Button>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Col>
                    </div>
                }
            </>
            <AdminFooter/>
        </>
    )
}

export default SearchDetail;