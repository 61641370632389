import axios from 'axios';
import api from "../../../../config/api.routes";
import authHeader from "../../../../services/auth-header";

const register = (data) => {
  let url = api().apiURL + `/v1/countHistory/register?`;
  console.log('방문기록 카운팅',url,data);
  return new Promise((resolve, reject) => {
    axios.post(url, data, { headers: authHeader() })
        .then(res => res.data)
        .then(res => resolve(res))
        .catch(e => reject(e));
  });
};

const countHistoryService = {
  register,
}

export default countHistoryService;
