import {Card, Col, Input, Row} from "reactstrap";
import {nextBlue, nextDetail, searchInfo} from "../../../assets/images";
import {categoryList, filePreviewState} from "../search/data/data";
import {isMobile} from "react-device-detect";
import {searchIconBlack} from "../../../assets/imagesM";
import React, {useEffect} from "react";
import useHistoryState from "../../../common/useHistoryState";
import searchService from "./service/search.service";
import PaginationWrapper from "../components/pagination";
import {useRecoilState} from "recoil";
import {useHistory, useLocation} from "react-router-dom";

function Search () {
    const history = useHistory();
    const { pathname } = useLocation();
    const [pageActive, setPageActive] = useHistoryState(1, 'pageActive');
    const [pageCount, setPageCount] = useHistoryState(1, 'pageCount');
    const [limit, setLimit] = useHistoryState(12, 'limit');
    const [sort, setSort] = useHistoryState('asc', 'sort');
    const [data, setData] = useHistoryState([], 'data');
    const [category, setCategory] = useRecoilState(filePreviewState);
    const [search, setSearch] = useHistoryState('', 'search');
    const [status, setStatus] = useHistoryState('', 'status');
    console.log(status)

    const render = () => {
        searchService.list({ search, page: pageActive, limit, category, sort, status })
            .then((res) => res.data)
            .then((res) => {
                if (res.statusCode === 200) {
                    console.log(res);
                    setPageCount(res.list.count);
                    setData(res.list.rows);
                }
            });
    }

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    useEffect(() => {
        render();
    }, [ pageActive, limit, search, sort, category, status ]);

    return (
        <>
            {isMobile
            ?
                <div style={{ fontFamily: 'regular' }}>
                    <Col xs="12" style={{ margin: '0 auto', padding: '5% 10%' }}>
                        <Row>
                            <Col xs="12">
                                <Row>
                                    {categoryList.map((v) => {
                                        return <>
                                            <Col xs="4" className="p-2 text-center">
                                                <Col
                                                    className="p-3"
                                                    onClick={(e) => {
                                                        setPageActive(1)
                                                        setCategory(v.name)
                                                    }}
                                                    style={
                                                        category === v.name
                                                            ?
                                                            {
                                                                backgroundColor: '#c8e0fc',
                                                                fontWeight: '600',
                                                                borderRadius: '10px',
                                                                fontSize: '1rem',
                                                                cursor: 'pointer'
                                                            }
                                                            :
                                                            {
                                                                backgroundColor: '#F8F8F8',
                                                                fontWeight: '600',
                                                                borderRadius: '10px',
                                                                fontSize: '1rem',
                                                                cursor: 'pointer'
                                                            }
                                                    }>
                                                    {v.name}
                                                </Col>
                                            </Col>
                                        </>
                                    })}
                                </Row>
                            </Col>
                        </Row>
                    </Col>
                    <div style={{ backgroundColor: '#f8f8f8', padding: '5% 4% 10%' }}>
                        <Col xs="12" style={{ marginTop: '5%' }}>
                            <Row>
                                <Col xs="12">
                                    <Input
                                        type="select"
                                        style={{ height: '40px', padding: '0 2%', borderRadius: '8px' }}
                                        value={status}
                                        onChange={(e) => setStatus(e.target.value)}
                                    >
                                        <option>전체</option>
                                        <option>진행중</option>
                                    </Input>
                                </Col>
                            </Row>
                        </Col>
                        <Col xs="12" style={{ marginTop: '5%' }}>
                            <Row>
                                <Col xs="12">
                                    <Input
                                        type="select"
                                        style={{ height: '40px', padding: '0 2%', borderRadius: '8px' }}
                                        value={sort}
                                        onChange={(e) => setSort(e.target.value)}
                                    >
                                        <option value="asc">최신순</option>
                                        <option value="desc">과거순</option>
                                        <option>스크랩순</option>
                                    </Input>
                                </Col>
                            </Row>
                        </Col>
                        <Col xs="12" style={{ marginTop: '5%' }}>
                            <Row>
                                <Col xs="12">
                                    <Input
                                        type="select"
                                        style={{ height: '40px', padding: '0 2%', borderRadius: '8px' }}
                                    >
                                        <option>검색구분</option>
                                        <option>제목</option>
                                        <option>내용</option>
                                    </Input>
                                </Col>
                            </Row>
                        </Col>
                        <Col xs="12" style={{ marginTop: '5%' }}>
                            <img
                                src={searchIconBlack}
                                style={{
                                    width: '7%',
                                    position: 'absolute',
                                    marginTop: '1.5%',
                                    marginLeft: '83%',
                                    zIndex: '100'
                                }}/>
                            <Row>
                                <Col xs="12">
                                    <Input
                                        placeholder="검색어 입력"
                                        type="text"
                                        style={{ height: '40px', borderRadius: '8px', backgroundColor: 'white' }}
                                        value={search}
                                        onChange={(e) => setSearch(e.target.value)}
                                    />
                                </Col>
                            </Row>
                        </Col>
                        <Col xs="12" style={{ marginTop: '5%' }}>
                            <Row>
                                <Col style={{ fontWeight: '700', fontSize: '1.1rem' }}>
                                    조회된 게시물
                                    <span style={{ color: '#5479f7', marginLeft: '2%' }}>
                                      {pageCount} 건
                                    </span>
                                </Col>
                            </Row>
                        </Col>
                        <Col xs="12" style={{ marginTop: '5%', padding: '0 6%' }}>
                            <Row>
                                <Col md="2">
                                </Col>
                                <Col md="10">
                                    <Row>
                                        {data?.map((v) => {
                                            return <>
                                                <Col md="4" className="p-2 text-center">
                                                    <Card
                                                        className="p-3"
                                                        style={{ fontWeight: '600', borderRadius: '10px', fontSize: '1rem', cursor: 'pointer' }}
                                                        onClick={(e) => history.push('/searchDetail/' + v.id)}
                                                    >
                                                        <Row>
                                                            <Col
                                                                xs="auto"
                                                                className="mx-3"
                                                                style={{ border: '1px solid #5479f7', borderRadius: '25px', fontSize: '0.9rem', lineHeight: '35px', color: '#5479f7' }}
                                                            >
                                                                {v.category}
                                                            </Col>
                                                            <Col
                                                                xs="auto"
                                                                style={{ color: 'white', backgroundColor: '#5479f7', borderRadius: '25px', fontSize: '0.9rem', lineHeight: '35px' }}
                                                            >
                                                                기간 : {v.term}
                                                            </Col>
                                                        </Row>
                                                        <Row className="mt-4">
                                                            <Col className="text-left font-weight-bolder">
                                                                {v.title}
                                                            </Col>
                                                        </Row>
                                                        <Row className="mt-2">
                                                            <Col className="text-left" style={{ fontSize: '0.9rem' }}>
                                                                {v.description}
                                                            </Col>
                                                        </Row>
                                                        <Row style={{ lineHeight: '40px', bottom: '0' }} onClick={(e) => history.push('/searchDetail/' + v.id)}>
                                                            <span className="text-right ml-3 font-weight-lighter" style={{ fontSize: '0.9rem' }}>자세히 보기</span>
                                                            <Col xs="auto" className="text-left">
                                                                <img src={nextDetail} style={{ width: '45%' }}/>
                                                            </Col>
                                                        </Row>
                                                    </Card>
                                                </Col>
                                            </>
                                        })}
                                    </Row>
                                    <Row style={{ margin: '0 auto' }}>
                                        <Col>
                                            <PaginationWrapper
                                                isActive={pageActive}
                                                pageNumber={pageCount}
                                                callback={(page) => setPageActive(page)}
                                                itemPerPage={4}
                                            />
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Col>
                    </div>
                </div>
            :
                <div style={{ fontFamily: 'regular' }}>
                    {/* 상단 */}
                    <Col md="8" style={{ margin: '0 auto', backgroundColor: 'white' }} >
                        <Row>
                            <Col md="2">
                                <Col className="border p-1 mt-4" style={{ borderRadius: '15px' }}>
                                    <Col className="border-bottom">
                                        <p
                                            style={{ fontSize: '1rem', color: '#5479f7', fontWeight: 'bold' }}
                                            className="my-2"
                                        >
                                            지원사업 조회
                                            <span className="ml-2">
                                                <img src={nextBlue} style={{ width: '8%' }}/>
                                            </span>
                                        </p>
                                    </Col>
                                    <Col>
                                        <p
                                            style={{ fontSize: '0.8rem', color: '#5c5c5c', fontWeight: 'bold' }}
                                            className="my-2"
                                        >
                                            지원사업 조회
                                        </p>
                                    </Col>
                                </Col>
                            </Col>
                            <Col md="10" className="mt-3">
                                <Row className="pl-1 mt-2 mb-5">
                                    <Col>
                                        <img src={searchInfo} style={{ width: '15%' }}/>
                                    </Col>
                                </Row>
                                <Row>
                                    {categoryList.map((v) => {
                                        return <>
                                            <Col md="4" className="p-2 text-center">
                                                <Col
                                                    className="p-3"
                                                    onClick={(e) => {
                                                        setPageActive(1)
                                                        setCategory(v.name)
                                                    }}
                                                    style={
                                                        category === v.name
                                                            ?
                                                                {
                                                                    backgroundColor: '#c8e0fc',
                                                                    fontWeight: '600',
                                                                    borderRadius: '10px',
                                                                    fontSize: '1rem',
                                                                    cursor: 'pointer'
                                                                }
                                                            :
                                                                {
                                                                    backgroundColor: '#F8F8F8',
                                                                    fontWeight: '600',
                                                                    borderRadius: '10px',
                                                                    fontSize: '1rem',
                                                                    cursor: 'pointer'
                                                                }
                                                    }>
                                                    {v.name}
                                                </Col>
                                            </Col>
                                        </>
                                    })}
                                </Row>
                            </Col>
                        </Row>
                    </Col>

                    {/* 중단 */}
                    <Col md="8" style={{ margin: '0 auto', marginTop: '5%' }}>
                        <Row>
                            <Col md="2">

                            </Col>
                            <Col md="10">
                                <Row>
                                    <Col md="2" style={{ backgroundColor: '#f0f0f0', padding: '1%' }}>
                                        <Input
                                            type="select"
                                            style={{ height: '40px', padding: '0 2%', borderRadius: '8px', backgroundColor: 'white' }}
                                            onChange={(e) => setStatus(e.target.value)}
                                        >
                                            <option value="전체">전체</option>
                                            <option value="진행중">진행중</option>
                                        </Input>
                                    </Col>
                                    <Col md="2" style={{ backgroundColor: '#f0f0f0', padding: '1%' }}>
                                        <Input
                                            type="select"
                                            style={{ height: '40px', padding: '0 2%', borderRadius: '8px', backgroundColor: 'white' }}
                                            // onChange={(e) => setStatus(e.target.value)}
                                        >
                                            <option>검색구분</option>
                                            <option>제목</option>
                                            <option>내용</option>
                                        </Input>
                                    </Col>
                                    <Col md="8" style={{ backgroundColor: '#f0f0f0', padding: '1%' }}>
                                        <img src={searchIconBlack} style={{ width: '2%', position: 'absolute', marginTop: '1%', marginLeft: '93%' }}/>
                                        <Input
                                            placeholder="검색어 입력"
                                            type="text"
                                            value={search}
                                            onChange={(e) => setSearch(e.target.value)}
                                            style={{ height: '40px', padding: '0 2%', borderRadius: '8px', backgroundColor: 'white' }}
                                        />
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Col>
                    {/* 중단 검색 */}
                    <Col md="8" style={{ margin: '0 auto', marginTop: '1%', marginBottom: '1.3%' }}>
                        <Row>
                            <Col md="2">

                            </Col>
                            <Col md="10">
                                <Row>
                                    <Col md="auto" style={{ fontWeight: '700', fontSize: '1.2rem' }}>조회된 게시물</Col>
                                    <Col style={{ color: '#5479f7', fontWeight: '800', fontSize: '1.2rem' }}>{pageCount} 건</Col>
                                    <Col md="2">
                                        <Input
                                            type="select"
                                            style={{ height: '40px', padding: '0 2%', borderRadius: '8px', backgroundColor: 'white' }}
                                            value={sort}
                                            onChange={(e) => setSort(e.target.value)}
                                        >
                                            <option value="asc">최신순</option>
                                            <option value="desc">과거순</option>
                                            <option>스크랩순</option>
                                        </Input>
                                    </Col>
                                    <Col md="2">
                                        <Input
                                            type="select"
                                            style={{ height: '40px', padding: '0 2%', borderRadius: '8px', backgroundColor: 'white' }}
                                            value={limit}
                                            onChange={(e) => setLimit(Number(e.target.value))}
                                        >
                                            <option value="6">6개씩</option>
                                            <option value="12">12개씩</option>
                                            <option value="24">24개씩</option>
                                            <option value="36">32개씩</option>
                                        </Input>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Col>
                    {/* 하단 리스트 */}
                    <Col md="8" style={{ margin: '0 auto' }}>
                        <Row>
                            <Col md="2">
                            </Col>
                            <Col md="10">
                                <Row>
                                    {data?.map((v) => {
                                        return <>
                                            <Col md="4" className="p-2 text-center">
                                                <Card
                                                    className="p-4"
                                                    onClick={(e) => history.push('/searchDetail/' + v.id)}
                                                    style={{
                                                        fontWeight: '600',
                                                        borderRadius: '10px',
                                                        fontSize: '1rem',
                                                        cursor: 'pointer',
                                                        height: '100%',
                                                    }}
                                                >
                                                    <Row>
                                                        <Col
                                                            md="auto"
                                                            className="mx-3"
                                                            style={{ border: '1px solid #5479f7', borderRadius: '35px', fontSize: '0.9rem', lineHeight: '35px', color: '#5479f7', paddingTop: '2px' }}
                                                        >
                                                            {v.category}
                                                        </Col>
                                                        <Col
                                                            md="auto"
                                                            style={{ color: 'white', backgroundColor: '#5479f7', borderRadius: '35px', fontSize: '0.9rem', lineHeight: '35px', paddingTop: '2px' }}
                                                        >
                                                            기간 : {v.term}
                                                        </Col>
                                                    </Row>

                                                    <Row className="mt-4">
                                                        <Col className="text-left font-weight-bolder">
                                                            {v.title}
                                                        </Col>
                                                    </Row>

                                                    <Row className="mt-2">
                                                        <Col className="text-left" style={{ fontSize: '0.9rem' }}>
                                                            {v.description?.length > 150
                                                                ? v.description.substring(0, 150) + " ...."
                                                                : v.description
                                                            }
                                                        </Col>
                                                    </Row>

                                                    <Row style={{ bottom: '0', position: 'absolute', padding: '2% 0' }}>
                                                        <Col
                                                            className="text-left"
                                                        >
                                                            <dvi className="text-right font-weight-lighter" style={{ fontSize: '0.9rem' }}>
                                                                자세히 보기
                                                                <img src={nextDetail} style={{ width: '16%', marginLeft: '7px' }}/>
                                                            </dvi>
                                                        </Col>
                                                    </Row>
                                                </Card>
                                            </Col>
                                        </>
                                    })}
                                </Row>
                                <Row style={{ marginTop: '3%', padding: '1% 0 3% 0' }}>
                                    <Col>
                                        <PaginationWrapper
                                            isActive={pageActive}
                                            pageNumber={pageCount}
                                            callback={(page) => setPageActive(page)}
                                            itemPerPage={10}
                                            limit={limit}
                                        />
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Col>
                </div>
            }
        </>
    )
}

export default Search;