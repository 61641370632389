export const techCategory = [
    '기업부설 연구소',
    '벤처기업 인증',
    '이노비즈 인증',
    '메인비즈 인증',
    '여성기업',
    '특허, 실용신안',
]

export const category = [
    '사업 운영 및 시설 자금',
    '소득 및 법인세의 부담',
    '특허 등 기술력 인증 및 취득',
    '고용비용 부담(노무 지원금)',
    '노무제도의 정비 및 검토',
    '국세 환급등의 청구',
    '법인 전환',
    '이익 잉여금의 처리',
    '기업의 가업 승계 및 양도양수',
    '주식 구조 변경',
    '기타 애로사항 기재',
]