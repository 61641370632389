export const categoryList = [
    {
        name: '창업',
        image: require('../../../../assets/images/main1.png').default
    },
    {
        name: '기술',
        image: require('../../../../assets/images/main2.png').default
    },
    {
        name: '금융',
        image: require('../../../../assets/images/main3.png').default
    },
    {
        name: '수출',
        image: require('../../../../assets/images/main4.png').default
    },
    {
        name: '경영',
        image: require('../../../../assets/images/main5.png').default
    },
    {
        name: '내수',
        image: require('../../../../assets/images/main6.png').default
    },
    {
        name: '인력',
        image: require('../../../../assets/images/main7.png').default
    },
    {
        name: '소상공인',
        image: require('../../../../assets/images/main8.png').default
    }
]