import {Button, Col, Input, Modal, ModalBody, ModalHeader, Row} from "reactstrap";
import {useRef, useState} from "react";
import {useHistory} from "react-router-dom";
import {isMobile} from "react-device-detect";
import AuthService from "./service/auth.service";
import {callImage} from "../../../assets/images";

function Login() {
    const history = useHistory()
    const [type, setType] = useState('login')
    const [focusedEmail, setFocusedEmail] = useState(false);
    const [focusedPassword, setFocusedPassword] = useState(false);
    const [id, setId] = useState('');
    const [pw, setPw] = useState('');
    const [modalChange, setModalChange] = useState(false);
    const notificationAlertRef = useRef(null);

    const isCheck = typeof window !== 'undefined' ? AuthService.getCurrentUser() : null;
    console.log(isCheck)
    if (isCheck) {
        if(isCheck?.role === '관리자') {
            history.push("/admin/dashboard");
        } else {
            history.push("/home");
        }
        return null;
    }
    //
    // const notify = (type, message) => {
    //     let options = {
    //         place: "tc",
    //         message: (
    //         <div className="alert-text">
    //             <span className="alert-title" data-notify="title">
    //             {" "}
    //                 로그인 오류!
    //             </span>
    //             <span data-notify="message">
    //                 {message}
    //             </span>
    //         </div>
    //         ),
    //         type: type,
    //         icon: "ni ni-bell-55",
    //         autoDismiss: 7,
    //     };
    //     notificationAlertRef.current.notificationAlert(options);
    // };

    const handleInputId = (e) => {
        setId(e.target.value);
    };

    const handlePassword = (e) => {
        setPw(e.target.value);
    };

    const goToMain = (e) => {
        e.preventDefault();

        console.log(id, pw)
        AuthService.login(id, pw)
            .then(
                (res) => {
                    console.log(res);
                    if (res.statusCode === 200) {
                        console.log(res)
                        history.push("/home");
                    } else {
                        alert(res.message)
                        // notify('danger', res.message);
                    }
                }).catch((error) => {
            const resMessage =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            // notify('danger', resMessage);
                    alert(resMessage)
        });
        console.log(id, pw);
    };

    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            goToMain(e);
        }
    };

    return (
        <>
            {isMobile
                ?
                <div style={{ fontFamily: 'regular' }}>
                    <div
                        style={{ textAlign: 'center', fontSize: '2rem', fontWeight: '900', margin: '15% 0' }}
                    >
                        정책자금 24 로그인
                    </div>
                    <div
                        style={{ padding: '0 5%', fontSize: '1.3rem', fontWeight: '700' }}
                    >
                        아이디
                    </div>
                    <div
                        style={{ padding: '2% 5% 4%', fontSize: '1.3rem', fontWeight: '700' }}
                    >
                        <Input
                            style={{ height: '40px' }}
                            placeholder="아이디"
                            type="email"
                            onFocus={() => setFocusedEmail(true)}
                            onBlur={() => setFocusedEmail(true)}
                            onChange={handleInputId}
                        />
                    </div>
                    <div
                        style={{ padding: '0 5%', fontSize: '1.3rem', fontWeight: '700' }}
                    >
                        비밀번호
                    </div>
                    <div
                        style={{ padding: '2% 5% 4%', fontSize: '1.3rem', fontWeight: '700' }}
                    >
                        <Input
                            style={{ height: '40px' }}
                            placeholder="비밀번호"
                            type="password"
                            onFocus={() => setFocusedPassword(true)}
                            onBlur={() => setFocusedPassword(true)}
                            onChange={handlePassword}
                            onKeyUp={handleKeyPress}
                        />
                    </div>
                    <div
                        style={{ padding: '5% 5% 4%', fontSize: '1.3rem', fontWeight: '700' }}
                    >
                        <Button
                            style={{ width: '100%', borderRadius: '25px', backgroundColor: '#5479f7', fontWeight: '900', padding: '4% 0' }}
                            onClick={goToMain}
                        >
                            로그인
                        </Button>
                    </div>
                    <Row style={{ margin: '3% 0 20% 0' }}>
                        <Col md="4"/>
                        <Col
                            md="4"
                            className="text-center"
                            style={{ fontSize: '0.9rem' }}
                        >
                            <span
                                style={{ cursor: 'pointer' }}
                                onClick={(e) => {
                                    history.push('/find')
                                    setType('findId')
                                }}
                            >
                                아이디 찾기
                            </span>
                            <sapn className="mx-4 text-muted font-weight-light">
                                |
                            </sapn>
                            <span
                                style={{ cursor: 'pointer' }}
                                onClick={(e) => {
                                    history.push('/reset')
                                    setType('resetPw')
                                }}
                            >
                                비밀번호 초기화
                            </span>
                            <sapn className="mx-4 text-muted font-weight-light">
                                |
                            </sapn>
                            <span
                                style={{ cursor: 'pointer' }}
                                onClick={(e) => {
                                    history.push('/join')
                                    setType('join')
                                }}
                            >
                                회원가입
                            </span>
                        </Col>
                        <Col md="4"/>
                    </Row>
                </div>
                :
                <div style={{ fontFamily: 'regular' }}>
                    <Row className="text-center">
                        <Col style={{ margin: '5% 0' }}>
                            <h1
                                style={{ fontWeight: '800' }}
                            >
                                정책자금 24 로그인
                            </h1>
                        </Col>
                    </Row>
                    <Row>
                        <Col md="4"/>
                        <Col md="4" className="">
                            <h3
                                style={{ fontWeight: '800' }}
                            >
                                아이디
                            </h3>
                        </Col>
                        <Col md="4"/>
                    </Row>
                    <Row>
                        <Col md="4"/>
                        <Col md="4">
                            <Input
                                style={{ minHeight: '50px' }}
                                placeholder="아이디"
                                type="email"
                                onFocus={() => setFocusedEmail(true)}
                                onBlur={() => setFocusedEmail(true)}
                                onChange={handleInputId}
                            />
                        </Col>
                        <Col md="4"/>
                    </Row>
                    <Row className="mt-4">
                        <Col md="4"/>
                        <Col md="4" className="">
                            <h3
                                style={{ fontWeight: '800' }}
                            >
                                비밀번호
                            </h3>
                        </Col>
                        <Col md="4"/>
                    </Row>
                    <Row>
                        <Col md="4"/>
                        <Col md="4" className="">
                            <Input
                                style={{ minHeight: '50px' }}
                                placeholder="비밀번호"
                                type="password"
                                onFocus={() => setFocusedPassword(true)}
                                onBlur={() => setFocusedPassword(true)}
                                onChange={handlePassword}
                                onKeyUp={handleKeyPress}
                            />
                        </Col>
                        <Col md="4"/>
                    </Row>
                    <Row className="mt-5 mb-4">
                        <Col md="4"/>
                        <Col md="4">
                            <Button
                                style={{
                                    lineHeight: '40px',
                                    width: '100%',
                                    backgroundColor: '#5479f7',
                                    borderColor: '#5479f7',
                                    borderRadius: '30px',
                                    fontSize: '1.2rem',
                                    fontWeight: 'bold'
                                }}
                                onClick={goToMain}
                            >
                                로그인
                            </Button>
                        </Col>
                        <Col md="4"/>
                    </Row>
                    <Row style={{ margin: '3% 0 5% 0' }}>
                        <Col md="4"/>
                        <Col
                            md="4"
                            className="font-weight-bold text-center"
                            style={{ fontSize: '1rem' }}
                        >
                            <span
                                style={{ cursor: 'pointer' }}
                                onClick={(e) => {
                                    // history.push('/find')
                                    setModalChange(true)
                                    setType('findId')
                                }}
                            >
                                아이디 찾기
                            </span>
                            <sapn className="mx-4 text-muted font-weight-light">
                                |
                            </sapn>
                            <span
                                style={{ cursor: 'pointer' }}
                                onClick={(e) => {
                                    // history.push('/reset')
                                    setModalChange(true)
                                    setType('resetPw')
                                }}
                            >
                                비밀번호 초기화
                            </span>
                            <sapn className="mx-4 text-muted font-weight-light">
                                |
                            </sapn>
                            <span
                                style={{ cursor: 'pointer' }}
                                onClick={(e) => {
                                    history.push('/join')
                                    setType('join')
                                }}
                            >
                                회원가입
                            </span>
                        </Col>
                        <Col md="4"/>
                    </Row>
                    <Modal
                        isOpen={modalChange}
                        toggle={() => setModalChange(false)}
                        className="modal-dialog-centered"
                        style={{ minWidth: '40%' }}
                        fade={false}
                    >
                        <ModalHeader style={{ margin: '0 auto', marginTop: '5%' }}>
                            <Row>
                                <img src={callImage} style={{ width: '60%', margin: '0 auto' }}/>
                            </Row>
                        </ModalHeader>
                        <ModalBody>
                            <Row className="my-4">
                                <Col className="text-center" style={{ fontSize: '2.2rem', fontWeight: '600' }}>
                                    아이디 찾기, 비밀번호 변경은<br/>
                                    업체에 문의해주세요!
                                </Col>
                            </Row>
                            <Row className="my-4">
                                <Col className="text-center" style={{ fontSize: '1.5rem', fontWeight: '400' }}>
                                    고객센터: 1670~2375~6
                                </Col>
                            </Row>
                            <Row className="justify-content-center my-5">
                                <Col md="8">
                                    <Button
                                        style={{
                                            lineHeight: '50px',
                                            width: '100%',
                                            backgroundColor: '#5479f7',
                                            borderColor: '#5479f7',
                                            borderRadius: '30px',
                                            fontSize: '1.5rem',
                                            fontWeight: 'bold',
                                        }}
                                        onClick={(e) => setModalChange(false)}
                                    >
                                        확인
                                    </Button>
                                </Col>
                            </Row>
                        </ModalBody>
                    </Modal>
                </div>
            }
        </>
    )
}

export default Login