const api = () => {
  let apiURL = 'https://api.kfund24.com';
  // let apiURL = 'https://api.coffits.net';

  const routes = {
    apiURL,
    LOGIN: `${apiURL}/v1/auth/login`,
  };

  return routes;
};

export default api;
