import {Button, Card, Col, Input, Row} from "reactstrap";
import {checkGreen, reserv01, reserv02} from "../../../assets/images";
import {category, techCategory} from "./data/data";
import {useState} from "react";
import {useHistory} from "react-router-dom";
import {isMobile} from "react-device-detect";
import {checkSuccess, selfCheckM, successReverv, userCheck, userChecked} from "../../../assets/imagesM";
import AuthService from "../login/service/auth.service";
import reservationService from "./service/reservation.service";
import {useRecoilState} from "recoil";
import {projectIdState} from "../search/data/data";

function Reservation () {
    const history = useHistory()
    const [info, setInfo] = useState(false)
    const [tech, setTech] = useState([])
    const [interest, setInterest] = useState([])
    const [success, setSuccess] = useState(false)
    const userName = JSON.parse(localStorage.getItem('user'))
    const isCheck = typeof window !== 'undefined' ? AuthService.getCurrentUser() : null;
    const [projectId, setProjectId] = useRecoilState(projectIdState);
    const [selfCheck, setSelfCheck] = useState([
        {
            type: 'agreeOne',
            title: '현재 사업자가 휴폐업 상태 입니까?',
            image: require('../../../assets/images/buttonOne.png').default,
            check: false
        },
        {
            type: 'agreeTwo',
            title: '대표자가 최대 주주가 아닙니까?',
            image: require('../../../assets/images/buttonTwo.png').default,
            check: false
        },
        {
            type: 'agreeThree',
            title: '대표자의 소송정보가 존재 합니까?',
            image: require('../../../assets/images/buttonThree.png').default,
            check: false
        },
        {
            type: 'agreeFour',
            title: '작년 대비 올해 매출이 20% 이상 감소 하였습니까?',
            image: require('../../../assets/images/buttonFour.png').default,
            check: false
        },
        {
            type: 'agreeFive',
            title: '최근 결산 년 기준 적자 및 결손 기업 입니까?',
            image: require('../../../assets/images/buttonFive.png').default,
            check: false
        },
        {
            type: 'agreeSix',
            title: '소재 사업장 및 실제경영자의 부동산에 가압류, 압류 정보가 \n' +
                '등록되어 있습니까?',
            image: require('../../../assets/images/buttonSix.png').default,
            check: false
        },
        {
            type: 'agreeSeven',
            title: '최근 3개월 이내 국세/지방세 체납 및 \n' +
                '연체 사실이 있습니까?',
            image: require('../../../assets/images/buttonSeven.png').default,
            check: false
        },
        {
            type: 'agreeEight',
            title: '법인 전환 및 대표자의 동종업 사업이력이 존재 합니까?',
            image: require('../../../assets/images/buttonEight.png').default,
            check: false
        }
    ])

    const [data, setData] = useState({
        date:'',
        time:'',
        agreeOne:'',
        agreeTwo:'',
        agreeThree:'',
        agreeFour:'',
        agreeFive:'',
        agreeSix:'',
        agreeSeven:'',
        agreeEight:'',
        pastPrice:'',
        nowPrice:'',
        count:'',
        nowCount:'',
        port:'해당사항 없음',
    })

    if (isCheck) {
        if(isCheck?.role === '개인') {
        } else {
        }
    } else {
        alert('회원 로그인 후 해당 서비스를 이용하실 수 있습니다.')
        history.push("/login");
    }

    const onChange = (e) => {
        let { value, name } = e.target;
        if (
            name === 'count' ||
            name === 'nowCount' ||
            name === 'pastPrice' ||
            name === 'nowPrice'
        ) {
            setData({
                ...data,
                [name]: Number(value.replace(/[^0-9]/g, '')),
            })
        } else {
            setData({
                ...data,
                [name]: value
            });
        }
    };

    const submit = async () => {
        if (isCheck?.role === '개인') {
            // if (!data.address) {
            //     return alert('주소를 입력은 필수입니다.');
            // }
        }

        const json = {
            date: data.date,
            time: data.time,
        }

        const comJson = {
            ...data,
            tech: JSON.stringify(tech),
            interest: JSON.stringify(interest),
            projectId
        }

        if (isCheck?.role === '기업') {
            selfCheck.map((checkItem) => {
                if (comJson.hasOwnProperty(checkItem.type)) {
                    comJson[checkItem.type] = checkItem.check ? checkItem.check : false;
                }
            });
        }

        let res;
        try{
            res = await reservationService.register(
                isCheck.role === '개인'
                    ? json
                    :comJson
            );
            if (res.statusCode === 200) {
                if (isCheck.role === '개인' ) {
                    setInfo(true)
                    setSuccess(true)
                } else {
                    setSuccess(true)
                }
                console.log('성공');
                alert('등록되었습니다.')
                sendCode()
            }
        } catch (e) {
            alert(e.response?.data?.message);
        }
    }

    const sendCode = async () => {
        // const tel = '01088511652'
        const tel = '01020017676'
        try {
            const res = await AuthService.reservationSuccess(tel);
            if (res.statusCode === 200) {
                // alert('인증번호를 전송했습니다.')
            }
        } catch (e) {
            alert(e.response?.data?.message);
        }
    };

    const isChecking = (value, type, setType) => {
        const index = type.indexOf(value); // value 값의 위치를 찾음.
        if (index > -1) { //  값이 있는 경우, -1은 값이없음.
            setType([
                ...type.filter(v => v !== value)
            ]);
        } else {
            setType([
                ...type,
                value
            ]);
        }
    }

    return (
        <>
            {isMobile
            ?
                <div style={{ fontFamily: 'regular' }}>
                    <Col xs="12" style={{ margin: '0 auto' }}>
                        {!info && !success && isCheck?.role === '기업' &&
                            <>
                                <div
                                    style={{ textAlign: 'center', fontSize: '2rem', fontWeight: '900', margin: '15% 0' }}
                                >
                                    상담 예약
                                </div>
                                <Row style={{ margin: '5% auto', width: '100%', textAlign: 'center' }}>
                                    <Col>
                                        <img src={selfCheckM}/>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col style={{margin: '5% auto 3%', borderTop: '2px solid black'}}/>
                                </Row>
                                <Row>
                                    <Col style={{fontSize: '1.2rem'}}>
                                        <p className="font-weight-bolder">자가진단</p>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <span style={{color: '#ffa954'}}>
                                            <img src={checkGreen} style={{ width: '6%' }}/>
                                        </span>
                                        <span style={{fontSize: '0.8rem', marginLeft: '1%' }}>
                                            아래의 리스트에 해당 사항을 모두 체크해 주시기 바랍니다.
                                        </span>
                                    </Col>
                                </Row>
                                <Row className="mt-3">
                                    {selfCheck.map((v, i) => {
                                        return <>
                                            <Col md="6">
                                                <Card style={{padding: '5%', borderRadius: '15px'}}>
                                                    <Row>
                                                        <Col>
                                                            <img src={v.image} style={{width: '13%'}}/>
                                                        </Col>
                                                    </Row>
                                                    <Row className="mt-4">
                                                        <Col>
                                                            <p style={{fontSize: '1.1rem', fontWeight: '600'}}>{v.title}</p>
                                                        </Col>
                                                    </Row>
                                                    <Row style={{marginTop: '5%'}}>
                                                        <Col xs="6" key={i}>
                                                            <div className="custom-control custom-checkbox mb-3">
                                                                <input
                                                                    className="custom-control-input"
                                                                    id={"customCheckOn" + i}
                                                                    type="checkbox"
                                                                    checked={v.check}
                                                                    onChange={() => {
                                                                        const array = selfCheck
                                                                        array[i].check = true
                                                                        setSelfCheck([
                                                                            ...array
                                                                        ])
                                                                    }}
                                                                    readOnly
                                                                />
                                                                <label
                                                                    className="custom-control-label"
                                                                    htmlFor={"customCheckOn" + i}
                                                                >
                                                                    해당
                                                                </label>
                                                            </div>
                                                        </Col>
                                                        <Col xs="6" key={i}>
                                                            <div className="custom-control custom-checkbox mb-3">
                                                                <input
                                                                    className="custom-control-input"
                                                                    id={"customCheckOff" + i}
                                                                    type="checkbox"
                                                                    checked={!v.check}
                                                                    onChange={() => {
                                                                        const array = selfCheck
                                                                        array[i].check = false
                                                                        setSelfCheck([
                                                                            ...array
                                                                        ])
                                                                    }}
                                                                    readOnly
                                                                />
                                                                <label
                                                                    className="custom-control-label"
                                                                    htmlFor={"customCheckOff" + i}
                                                                >
                                                                    해당사항 없음
                                                                </label>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </Card>
                                            </Col>
                                        </>
                                    })}
                                </Row>
                                <Row className="mt-2">
                                    <Col xs="12">
                                        <p style={{fontWeight: '800', fontSize: '1.2rem'}}>
                                            작년 매출액 <sapn className="text-muted" style={{ fontSize: '0.9rem' }}>(단위: 백만원)</sapn>
                                        </p>
                                    </Col>
                                    <Col xs="12">
                                        <Input
                                            placeholder="숫자만 입력"
                                            type="text"
                                            style={{height: '40px', borderRadius: '8px'}}
                                            onChange={onChange}
                                            name="pastPrice"
                                        />
                                    </Col>
                                </Row>
                                <Row style={{ marginTop: '8%' }}>
                                    <Col xs="12">
                                        <p style={{fontWeight: '800', fontSize: '1.2rem'}}>
                                            최근 3개월 매출액 <sapn className="text-muted" style={{ fontSize: '0.9rem' }}>(단위: 백만원)</sapn>
                                        </p>
                                    </Col>
                                    <Col xs="12">
                                        <Input
                                            placeholder="숫자만 입력"
                                            type="text"
                                            style={{height: '40px', borderRadius: '8px'}}
                                            onChange={onChange}
                                            name="nowPrice"
                                        />
                                    </Col>
                                </Row>
                                <Row style={{ marginTop: '8%' }}>
                                    <Col xs="12">
                                        <p style={{fontWeight: '800', fontSize: '1.2rem'}}>
                                            4대보험 등록 직원 수
                                        </p>
                                    </Col>
                                    <Col xs="12">
                                        <Input
                                            placeholder="숫자만 입력"
                                            type="text"
                                            style={{height: '40px', borderRadius: '8px'}}
                                            onChange={onChange}
                                            name="count"
                                        />
                                    </Col>
                                </Row>
                                <Row style={{ marginTop: '8%' }}>
                                    <Col xs="12">
                                        <p style={{fontWeight: '800', fontSize: '1.2rem'}}>
                                            당해 채용 예정 인원
                                        </p>
                                    </Col>
                                    <Col xs="12">
                                        <Input
                                            placeholder="숫자만 입력"
                                            type="text"
                                            style={{height: '40px', borderRadius: '8px'}}
                                            onChange={onChange}
                                            name="nowCount"
                                        />
                                    </Col>
                                </Row>
                                <Row style={{ marginTop: '8%' }}>
                                    <Col md="6">
                                        <p style={{fontWeight: '800', fontSize: '1.2rem'}}>
                                            수출입 여부
                                        </p>
                                    </Col>
                                    <Col xs="12">
                                        <Row>
                                            <Col xs="6">
                                                <div className="custom-control custom-checkbox mb-3">
                                                    <input
                                                        className="custom-control-input"
                                                        id={"customCheck111"}
                                                        type="checkbox"
                                                        checked={data.port === '수출'}
                                                        onClick={(e) => setData({ ...data, port: '수출' })}
                                                        readOnly
                                                    />
                                                    <label
                                                        className="custom-control-label"
                                                        htmlFor={"customCheck111"}
                                                    >
                                                        수출
                                                    </label>
                                                </div>
                                            </Col>
                                            <Col xs="6">
                                                <div className="custom-control custom-checkbox mb-3">
                                                    <input
                                                        className="custom-control-input"
                                                        id={"customCheck112"}
                                                        type="checkbox"
                                                        checked={data.port === '수입'}
                                                        onClick={(e) => setData({ ...data, port: '수입' })}
                                                        readOnly
                                                    />
                                                    <label
                                                        className="custom-control-label"
                                                        htmlFor={"customCheck112"}
                                                    >
                                                        수입
                                                    </label>
                                                </div>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                                <Row style={{ marginTop: '8%' }}>
                                    <Col xs="12">
                                        <p style={{fontWeight: '800', fontSize: '1.2rem'}}>
                                            기술인증 현황
                                        </p>
                                    </Col>
                                    <Col xs="12">
                                        <Row>
                                            {techCategory.map((v, i) => {
                                                return <>
                                                    <Col xs="6">
                                                        <div className="custom-control custom-checkbox mb-2">
                                                            <input
                                                                className="custom-control-input"
                                                                id={"customCheck13" + i}
                                                                type="checkbox"
                                                                readOnly
                                                                value={v}
                                                                onClick={() => isChecking(v, tech, setTech)}
                                                                checked={tech.indexOf(v) > -1}
                                                                name="tech"
                                                            />
                                                            <label
                                                                className="custom-control-label"
                                                                htmlFor={"customCheck13" + i}
                                                            >
                                                                <p style={{fontSize: '0.9rem', fontWeight: '500'}}>{v}</p>
                                                            </label>
                                                        </div>
                                                    </Col>
                                                </>
                                            })}
                                        </Row>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col style={{margin: '5% auto 3%', borderTop: '0.1px solid lightgray'}}/>
                                </Row>
                                <Row>
                                    <Col style={{fontSize: '1.2rem'}}>
                                        <p className="font-weight-bolder">관심분야 및 애로사항</p>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col style={{marginTop: ''}}>
                                        <span style={{color: '#ffa954'}}>
                                            <img src={checkGreen} style={{width: '6%'}}/>
                                        </span>
                                        <span style={{fontSize: '0.8rem', marginLeft: '1%'}}>
                                            관심분야 및 기업의 애로가 되는 사항을 체크해 주시면 사업지원 방향에 큰 도움이 됩니다.
                                        </span>
                                    </Col>
                                </Row>
                                <Row className="mt-5">
                                    <Col>
                                        <Card
                                            style={{padding: '3% 5%', borderRadius: '15px'}}
                                        >
                                            <Row>
                                                {category.map((v, i) => {
                                                    return <>
                                                        <Col md="3">
                                                            <div className="custom-control custom-checkbox mb-2">
                                                                <input
                                                                    className="custom-control-input"
                                                                    id={"customCheck12" + i}
                                                                    type="checkbox"
                                                                    value={data.interest}
                                                                    onClick={() => isChecking(v, interest, setInterest)}
                                                                    checked={interest.indexOf(v) > -1}
                                                                    name="tech"
                                                                />
                                                                <label
                                                                    className="custom-control-label"
                                                                    htmlFor={"customCheck12" + i}
                                                                >
                                                                    <p style={{fontSize: '0.9rem', fontWeight: '500'}}>{v}</p>
                                                                </label>
                                                            </div>
                                                        </Col>
                                                    </>
                                                })}
                                            </Row>
                                        </Card>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col style={{margin: '0 auto', textAlign: 'center'}}>
                                        <Button
                                            style={{
                                                lineHeight: '45px',
                                                width: '100%',
                                                backgroundColor: '#5479f7',
                                                borderRadius: '50px',
                                                fontSize: '1.5rem',
                                                fontWeight: '800',
                                                margin: '5% 0 7% 0',
                                            }}
                                            onClick={(e) => {
                                                if(
                                                    !data.pastPrice ||
                                                    !data.nowPrice ||
                                                    !data.count ||
                                                    !data.nowCount ||
                                                    !tech[0] ||
                                                    !interest[0]
                                                ) {
                                                    return alert('모든 설문에 참여해주세요.')
                                                } else {
                                                    setInfo(true)
                                                }
                                            }}
                                        >
                                            다음
                                        </Button>
                                    </Col>
                                </Row>
                            </>
                        }
                        {!info && !success && isCheck?.role === '개인' &&
                            <>
                                <div
                                    style={{ textAlign: 'center', fontSize: '2rem', fontWeight: '900', margin: '15% 0' }}
                                >
                                    상담 예약
                                </div>
                                <Row style={{ margin: '5% auto', width: '100%', textAlign: 'center' }}>
                                    <Col>
                                        <img src={userCheck}/>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col style={{ margin: '5% auto 3%', borderTop: '2px solid black' }}/>
                                </Row>
                                <Row>
                                    <Col style={{ fontSize: '1.2rem' }}>
                                        <p className="font-weight-bolder">상담 예약 시간을 입력하세요</p>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col style={{ marginTop: '10px' }}>
                                        <span style={{ color: '#ffa954' }}>
                                            <img src={checkGreen} style={{ width: '6%' }} />
                                        </span>
                                        <span style={{ fontSize: '0.8rem', marginLeft: '1%' }}>
                                            대표자의 상담이 가능한 날짜와 시간을 입력하여 주시기 바랍니다
                                        </span>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col style={{ margin: '3% auto 3%', borderTop: '0.1px solid lightgray' }}/>
                                </Row>
                                <Row className="mt-2">
                                    <Col xs="12">
                                        <p style={{ fontWeight: '800', fontSize: '1.2rem' }}>
                                            날짜
                                        </p>
                                    </Col>
                                    <Col xs="12">
                                        <Input
                                            type="date"
                                            style={{ height: '40px', borderRadius: '8px', fontSize: '0.9rem' }}
                                            value={data.date}
                                            dateFormat={'YYYY-MM-DD'}
                                            onChange={(e) =>
                                                setData({ ...data, date: e.target.value })
                                            }
                                        />
                                    </Col>
                                </Row>
                                <Row style={{ marginTop: '8%' }}>
                                    <Col xs="12">
                                        <p style={{ fontWeight: '800', fontSize: '1.2rem' }}>
                                            시간
                                        </p>
                                    </Col>
                                    <Col xs="12">
                                        <Input
                                            type="time"
                                            style={{ height: '40px', borderRadius: '8px', fontSize: '0.9rem' }}
                                            value={data.time}
                                            dateFormat={'HH:mm:ss'}
                                            onChange={(e) =>
                                                setData({ ...data, time: e.target.value })
                                            }
                                        />
                                    </Col>
                                </Row>
                                <Row style={{ margin: '8% 0 15% 0' }}>
                                    <Col style={{ margin: '0 auto', textAlign: 'center' }}>
                                        <Button
                                            style={{
                                                lineHeight: '45px',
                                                width: '100%',
                                                backgroundColor: '#5479f7',
                                                borderRadius: '50px',
                                                fontSize: '1.5rem',
                                                fontWeight: '800',
                                                margin: '5% 0 7% 0'
                                            }}
                                            onClick={(e) => setSuccess(true)}
                                        >
                                            다음
                                        </Button>
                                    </Col>
                                </Row>
                            </>
                        }
                        {!success && info &&
                            <>
                                <div
                                    style={{ textAlign: 'center', fontSize: '2rem', fontWeight: '900', margin: '15% 0' }}
                                >
                                    상담 예약
                                </div>
                                <Row style={{ margin: '5% auto', width: '100%', textAlign: 'center' }}>
                                    <Col>
                                        <img src={checkSuccess}/>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col style={{ margin: '5% auto 3%', borderTop: '2px solid black' }}/>
                                </Row>
                                <Row>
                                    <Col style={{ fontSize: '1.2rem' }}>
                                        <p className="font-weight-bolder">상담 예약 시간을 입력하세요</p>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col style={{ marginTop: '10px' }}>
                                        <span style={{ color: '#ffa954' }}>
                                            <img src={checkGreen} style={{ width: '6%' }} />
                                        </span>
                                        <span style={{ fontSize: '0.8rem', marginLeft: '1%' }}>
                                            대표자의 상담이 가능한 날짜와 시간을 입력하여 주시기 바랍니다
                                        </span>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col style={{ margin: '3% auto 3%', borderTop: '0.1px solid lightgray' }}/>
                                </Row>
                                <Row className="mt-2">
                                    <Col xs="12">
                                        <p style={{ fontWeight: '800', fontSize: '1.2rem' }}>
                                            날짜
                                        </p>
                                    </Col>
                                    <Col xs="12">
                                        <Input
                                            type="date"
                                            style={{ height: '40px', borderRadius: '8px', fontSize: '0.9rem' }}
                                            value={data.date}
                                            dateFormat={'YYYY-MM-DD'}
                                            onChange={(e) =>
                                                setData({ ...data, date: e.target.value })
                                            }
                                        />
                                    </Col>
                                </Row>
                                <Row style={{ marginTop: '8%' }}>
                                    <Col xs="12">
                                        <p style={{ fontWeight: '800', fontSize: '1.2rem' }}>
                                            시간
                                        </p>
                                    </Col>
                                    <Col xs="12">
                                        <Input
                                            type="time"
                                            style={{ height: '40px', borderRadius: '8px', fontSize: '0.9rem' }}
                                            value={data.time}
                                            dateFormat={'HH:mm:ss'}
                                            onChange={(e) =>
                                                setData({ ...data, time: e.target.value })
                                            }
                                        />
                                    </Col>
                                </Row>
                                <Row style={{ margin: '8% 0 15% 0' }}>
                                    <Col style={{ margin: '0 auto', textAlign: 'center' }}>
                                        <Button
                                            style={{
                                                lineHeight: '45px',
                                                width: '100%',
                                                backgroundColor: '#5479f7',
                                                borderRadius: '50px',
                                                fontSize: '1.5rem',
                                                fontWeight: '800',
                                                margin: '5% 0 7% 0'
                                            }}
                                            onClick={(e) => setSuccess(true)}
                                        >
                                            다음
                                        </Button>
                                    </Col>
                                </Row>
                            </>
                        }
                        {success &&
                            <>
                                <div
                                    style={{ textAlign: 'center', fontSize: '2rem', fontWeight: '900', margin: '15% 0' }}
                                >
                                    상담 예약
                                </div>
                                <Row style={{ margin: '5% auto', width: '100%', textAlign: 'center' }}>
                                    <Col>
                                        {isCheck?.role === '개인'
                                            ?   <img src={userChecked}/>
                                            :   <img src={checkSuccess}/>
                                        }
                                    </Col>
                                </Row>
                                <Row>
                                    <Col style={{ margin: '5% auto 3%', borderTop: '2px solid black' }}/>
                                </Row>
                                <Row style={{ marginTop: '10%' }}>
                                    <Col xs="12" style={{ margin: '0 auto', textAlign: 'center' }}>
                                        <img src={successReverv} style={{ width: '50%' }}/>
                                    </Col>
                                </Row>
                                <Row style={{ marginTop: '10%' }}>
                                    <Col xs="12" style={{ margin: '0 auto', textAlign: 'center' }}>
                                        <span style={{ fontSize: '1.5rem' }}>
                                            {userName?.name} 의
                                            <br/><span style={{ fontWeight: '900', fontSize: '1.5rem' }}>상담 예약 접수</span>되었습니다.
                                        </span>
                                    </Col>
                                </Row>
                                <Row style={{ marginTop: '5%' }}>
                                    <Col style={{ margin: '0 auto', textAlign: 'center' }}>
                                        <span style={{ fontSize: '1.2rem', fontWeight: '500' }}>
                                            {localStorage.getItem('user')?.name}의 예약이 접수되었습니다.<br/>
                                            접수일 기준 2~3일 이내 지역 담당자를 배정하여 방문 전 연락드릴 예정입니다.
                                        </span>
                                    </Col>
                                </Row>
                                <Row style={{ margin: '10% 0 15% 0' }}>
                                    <Col style={{ margin: '0 auto', textAlign: 'center' }}>
                                        <Button
                                            style={{
                                                lineHeight: '45px',
                                                width: '100%',
                                                backgroundColor: '#5479f7',
                                                borderRadius: '50px',
                                                fontSize: '1.5rem',
                                                fontWeight: '800',
                                                margin: '3% 0 5% 0'
                                            }}
                                            onClick={(e) => history.push('/home')}
                                        >
                                            메인으로 이동
                                        </Button>
                                    </Col>
                                </Row>
                            </>
                        }
                    </Col>
                </div>
            :
                <div style={{ fontFamily: 'regular' }}>
                    <Col md="8" style={{ margin: '0 auto' }}>
                        {!info && !success && isCheck?.role === '기업' &&
                            <>
                                <Row>
                                    <Col md="12">
                                        <Row>
                                            <Col className="text-center" style={{margin: '8% 0'}}>
                                                <span style={{ fontSize: '3rem', fontWeight: '700' }}>상담 예약</span>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md="12" style={{ margin: '0 auto' }}>
                                        <Row>
                                            <Col>
                                                <img src={reserv01} />
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col style={{margin: '5% auto 3%', borderTop: '2px solid black'}}/>
                                </Row>
                                <Row>
                                    <Col md="12" style={{fontSize: '1.8rem'}}>
                                        <span className="font-weight-bolder">자가진단</span>
                                    </Col>
                                    <Col md="12">
                                        <span style={{color: '#ffa954'}}>
                                            <img src={checkGreen} style={{width: '1.5%'}}/>
                                        </span>
                                        <span style={{fontSize: '0.8rem', marginLeft: '1%'}}>
                                            아래의 리스트에 해당 사항을 모두 체크해 주시기 바랍니다.
                                        </span>
                                    </Col>
                                </Row>
                                <Row className="mt-5">
                                    {selfCheck.map((v, i) => {
                                        return <>
                                            <Col md="6">
                                                <Card style={{padding: '5%', borderRadius: '15px', height: '90%'}}>
                                                    <Row>
                                                        <Col>
                                                            <img src={v.image} style={{width: '10%'}}/>
                                                        </Col>
                                                    </Row>
                                                    <Row className="mt-4">
                                                        <Col>
                                                            <p style={{fontSize: '1.1rem', fontWeight: '700'}}>{v.title}</p>
                                                        </Col>
                                                    </Row>
                                                    <Row style={{marginTop: '5%'}}>
                                                        <Col md="auto" key={i}>
                                                            <div className="custom-control custom-checkbox mb-3">
                                                                <input
                                                                    className="custom-control-input"
                                                                    id={"customCheck" + i}
                                                                    type="checkbox"
                                                                    checked={v.check}
                                                                    onChange={() => {
                                                                        const array = selfCheck
                                                                        array[i].check = true
                                                                        setSelfCheck([
                                                                            ...array
                                                                        ])
                                                                    }}
                                                                    readOnly
                                                                />
                                                                <label
                                                                    className="custom-control-label"
                                                                    htmlFor={"customCheck" + i}
                                                                >
                                                                    해당
                                                                </label>
                                                            </div>
                                                        </Col>
                                                        <Col md="auto" key={i}>
                                                            <div className="custom-control custom-checkbox mb-3">
                                                                <input
                                                                    className="custom-control-input"
                                                                    id={"customChecked" + i}
                                                                    type="checkbox"
                                                                    checked={!v.check}
                                                                    onChange={() => {
                                                                        const array = selfCheck
                                                                        array[i].check = false
                                                                        setSelfCheck([
                                                                            ...array
                                                                        ])
                                                                    }}
                                                                    readOnly
                                                                />
                                                                <label
                                                                    className="custom-control-label"
                                                                    htmlFor={"customChecked" + i}
                                                                >
                                                                    해당사항 없음
                                                                </label>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </Card>
                                            </Col>
                                        </>
                                    })}
                                </Row>
                                <Row className="mt-5">
                                    <Col md="6">
                                        <p style={{fontWeight: '800', fontSize: '1.2rem'}}>
                                            작년 매출액 <sapn className="text-muted" style={{ fontSize: '0.9rem' }}>(단위: 백만원)</sapn>
                                        </p>
                                    </Col>
                                    <Col md="6">
                                        <p style={{fontWeight: '800', fontSize: '1.2rem'}}>
                                            최근 3개월 매출액 <sapn className="text-muted" style={{ fontSize: '0.9rem' }}>(단위: 백만원)</sapn>
                                        </p>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md="6">
                                        <Input
                                            placeholder="숫자만 입력하세요"
                                            type="text"
                                            style={{height: '40px', borderRadius: '8px', fontSize: '0.9rem', fontWeight: '300'}}
                                            value={data.pastPrice}
                                            onChange={onChange}
                                            name="pastPrice"
                                        >

                                        </Input>
                                    </Col>
                                    <Col md="6">
                                        <Input
                                            placeholder="숫자만 입력하세요"
                                            type="text"
                                            style={{height: '40px', borderRadius: '8px', fontSize: '0.9rem', fontWeight: '300'}}
                                            value={data.nowPrice}
                                            onChange={onChange}
                                            name="nowPrice"
                                        >

                                        </Input>
                                    </Col>
                                </Row>
                                <Row className="mt-5">
                                    <Col md="6">
                                        <p style={{fontWeight: '800', fontSize: '1.2rem'}}>
                                            4대보험 등록 직원 수
                                        </p>
                                    </Col>
                                    <Col md="6">
                                        <p style={{fontWeight: '800', fontSize: '1.2rem'}}>
                                            당해 채용 예정 인원
                                        </p>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md="6">
                                        <Input
                                            placeholder="숫자만 입력하세요"
                                            type="text"
                                            style={{height: '40px', borderRadius: '8px', fontSize: '0.9rem', fontWeight: '300'}}
                                            value={data.count}
                                            onChange={onChange}
                                            name="count"
                                        >

                                        </Input>
                                    </Col>
                                    <Col md="6">
                                        <Input
                                            placeholder="숫자만 입력하세요"
                                            type="text"
                                            style={{height: '40px', borderRadius: '8px', fontSize: '0.9rem', fontWeight: '300'}}
                                            value={data.nowCount}
                                            onChange={onChange}
                                            name="nowCount"
                                        >

                                        </Input>
                                    </Col>
                                </Row>
                                <Row className="mt-5">
                                    <Col md="6">
                                        <p style={{fontWeight: '800', fontSize: '1.2rem'}}>
                                            수출입 여부
                                        </p>
                                    </Col>
                                    <Col md="6">
                                        <p style={{fontWeight: '800', fontSize: '1.2rem'}}>
                                            기술인증 현황
                                        </p>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md="6">
                                        <Row>
                                            <Col md="auto">
                                                <div className="custom-control custom-checkbox mb-3">
                                                    <input
                                                        className="custom-control-input"
                                                        id={"customCheckPort1"}
                                                        type="checkbox"
                                                        checked={data.port === '수출'}
                                                        onChange={(e) => setData({ ...data, port: '수출' })}
                                                        readOnly
                                                    />
                                                    <label
                                                        className="custom-control-label"
                                                        htmlFor={"customCheckPort1"}
                                                    >
                                                        수출
                                                    </label>
                                                </div>
                                            </Col>
                                            <Col md="auto">
                                                <div className="custom-control custom-checkbox mb-3">
                                                    <input
                                                        className="custom-control-input"
                                                        id={"customCheckPort2"}
                                                        type="checkbox"
                                                        checked={data.port === '수입'}
                                                        onChange={(e) => setData({ ...data, port: '수입' })}
                                                        readOnly
                                                    />
                                                    <label
                                                        className="custom-control-label"
                                                        htmlFor={"customCheckPort2"}
                                                    >
                                                        수입
                                                    </label>
                                                </div>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col md="6">
                                        <Row>
                                            {techCategory.map((v, i) => {
                                                return <>
                                                    <Col md="4">
                                                        <div className="custom-control custom-checkbox mb-2">
                                                            <input
                                                                className="custom-control-input"
                                                                id={"customCheck13" + i}
                                                                type="checkbox"
                                                                readOnly
                                                                value={v}
                                                                onClick={() => isChecking(v, tech, setTech)}
                                                                checked={tech.indexOf(v) > -1}
                                                                name="tech"
                                                            />
                                                            <label
                                                                className="custom-control-label"
                                                                htmlFor={"customCheck13" + i}
                                                            >
                                                                <p style={{fontSize: '0.9rem', fontWeight: '500'}}>{v}</p>
                                                            </label>
                                                        </div>
                                                    </Col>
                                                </>
                                            })}
                                        </Row>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col style={{margin: '5% auto 3%', borderTop: '0.1px solid lightgray'}}/>
                                </Row>
                                <Row>
                                    <Col style={{fontSize: '1.2rem'}}>
                                        <p className="font-weight-bolder">관심분야 및 애로사항</p>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col style={{marginTop: '10px'}}>
                                <span style={{color: '#ffa954'}}>
                                    <img src={checkGreen} style={{width: '1.5%'}}/>
                                </span>
                                        <span style={{fontSize: '0.8rem', marginLeft: '1%'}}>
                                    관심분야 및 기업의 애로가 되는 사항을 체크해 주시면 사업지원 방향에 큰 도움이 됩니다.
                                </span>
                                    </Col>
                                </Row>
                                <Row className="mt-5">
                                    <Col>
                                        <Card
                                            style={{padding: '3% 5%', borderRadius: '15px'}}
                                        >
                                            <Row>
                                                {category.map((v, i) => {
                                                    return <>
                                                        <Col md="3">
                                                            <div className="custom-control custom-checkbox mb-2">
                                                                <input
                                                                    className="custom-control-input"
                                                                    id={"customCheck12" + i}
                                                                    type="checkbox"
                                                                    value={data.interest}
                                                                    onClick={() => isChecking(v, interest, setInterest)}
                                                                    checked={interest.indexOf(v) > -1}
                                                                    name="tech"
                                                                />
                                                                <label
                                                                    className="custom-control-label"
                                                                    htmlFor={"customCheck12" + i}
                                                                >
                                                                    <p style={{fontSize: '0.9rem', fontWeight: '500'}}>{v}</p>
                                                                </label>
                                                            </div>
                                                        </Col>
                                                    </>
                                                })}
                                            </Row>
                                        </Card>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col style={{margin: '0 auto', textAlign: 'center'}}>
                                        <Button
                                            style={{
                                                lineHeight: '50px',
                                                width: '25%',
                                                backgroundColor: '#5479f7',
                                                borderRadius: '50px',
                                                fontSize: '1.4rem',
                                                fontWeight: '800',
                                                margin: '5% 0 7% 0'
                                            }}
                                            onClick={(e) => {
                                                if(
                                                    !data.pastPrice ||
                                                    !data.nowPrice ||
                                                    !data.count ||
                                                    !data.nowCount ||
                                                    !tech[0] ||
                                                    !interest[0]
                                                ) {
                                                    return alert('모든 설문에 참여해주세요.')
                                                } else {
                                                    setInfo(true)
                                                }
                                            }}
                                        >
                                            다음
                                        </Button>
                                    </Col>
                                </Row>
                            </>
                        }
                        {!success && isCheck?.role === '개인' &&
                            <>
                                <Row>
                                    <Col md="12">
                                        <Row>
                                            <Col className="text-center" style={{ marginTop: '8%' }}>
                                                <h1 className="font-weight-bolder">상담 예약</h1>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md="12" style={{ margin: '0 auto' }}>
                                        <Row>
                                            <Col>
                                                <img src={reserv02} />
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                                {/*<Row>*/}
                                {/*    <Col style={{ backgroundColor: '#C7E0FC', padding: '1% 4%', textAlign: 'center', fontWeight: '600' }}>*/}
                                {/*        01. 상담일정 선택*/}
                                {/*    </Col>*/}
                                {/*    <Col style={info*/}
                                {/*        ? {backgroundColor: '#f8f8f8', padding: '1% 4%', textAlign: 'center', fontWeight: '600'}*/}
                                {/*        : {*/}
                                {/*            backgroundColor: '#f8f8f8',*/}
                                {/*            padding: '1% 4%',*/}
                                {/*            textAlign: 'center',*/}
                                {/*            fontWeight: '600'*/}
                                {/*        }}*/}
                                {/*    >*/}
                                {/*        02. 예약 완료*/}
                                {/*    </Col>*/}
                                {/*</Row>*/}
                                <Row>
                                    <Col style={{ margin: '5% auto 3%', borderTop: '2px solid black' }}/>
                                </Row>
                                <Row>
                                    <Col style={{ fontSize: '1.2rem' }}>
                                        <p className="font-weight-bolder">상담 예약 시간을 입력하세요</p>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col style={{ marginTop: '10px' }}>
                                        <span style={{ color: '#ffa954' }}>
                                            <img src={checkGreen} style={{ width: '1.5%' }} />
                                        </span>
                                        <span style={{ fontSize: '0.8rem', marginLeft: '1%' }}>
                                            대표자의 상담이 가능한 날짜와 시간을 입력하여 주시기 바랍니다
                                        </span>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col style={{ margin: '3% auto 3%', borderTop: '0.1px solid lightgray' }}/>
                                </Row>
                                <Row className="mt-2">
                                    <Col md="6">
                                        <p style={{ fontWeight: '800', fontSize: '1.2rem' }}>
                                            날짜
                                        </p>
                                    </Col>
                                    <Col md="6">
                                        <p style={{ fontWeight: '800', fontSize: '1.2rem' }}>
                                            시간
                                        </p>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md="6">
                                        <Input
                                            type="date"
                                            style={{ height: '40px', borderRadius: '8px', fontSize: '0.9rem' }}
                                            value={data.date}
                                            dateFormat={'YYYY-MM-DD'}
                                            onChange={(e) =>
                                                setData({ ...data, date: e.target.value })
                                            }
                                        />
                                    </Col>
                                    <Col md="6">
                                        <Input
                                            type="time"
                                            style={{ height: '40px', borderRadius: '8px', fontSize: '0.9rem' }}
                                            value={data.time}
                                            dateFormat={'HH:mm:ss'}
                                            onChange={(e) =>
                                                setData({ ...data, time: e.target.value })
                                            }
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col style={{ margin: '0 auto', textAlign: 'center' }}>
                                        <Button
                                            style={{
                                                lineHeight: '50px',
                                                width: '25%',
                                                backgroundColor: '#5479f7',
                                                borderRadius: '50px',
                                                fontSize: '1.4rem',
                                                fontWeight: '800',
                                                margin: '5% 0 7% 0'
                                            }}
                                            onClick={(e) => submit()}
                                        >
                                            다음
                                        </Button>
                                    </Col>
                                </Row>
                            </>
                        }
                        {!success && info &&
                            <>
                                <Row>
                                    <Col md="12">
                                        <Row>
                                            <Col className="text-center" style={{ marginTop: '8%' }}>
                                                <h1 className="font-weight-bolder">상담 예약</h1>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md="12" style={{ margin: '0 auto' }}>
                                        <Row>
                                            <Col>
                                                <img src={reserv02} />
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col style={{ margin: '5% auto 3%', borderTop: '2px solid black' }}/>
                                </Row>
                                <Row>
                                    <Col style={{ fontSize: '1.2rem' }}>
                                        <p className="font-weight-bolder">상담 예약 시간을 입력하세요</p>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col style={{ marginTop: '10px' }}>
                                        <span style={{ color: '#ffa954' }}>
                                            <img src={checkGreen} style={{ width: '1.5%' }} />
                                        </span>
                                        <span style={{ fontSize: '0.8rem', marginLeft: '1%' }}>
                                            대표자의 상담이 가능한 날짜와 시간을 입력하여 주시기 바랍니다
                                        </span>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col style={{ margin: '3% auto 3%', borderTop: '0.1px solid lightgray' }}/>
                                </Row>
                                <Row className="mt-2">
                                    <Col md="6">
                                        <p style={{ fontWeight: '800', fontSize: '1.2rem' }}>
                                            날짜
                                        </p>
                                    </Col>
                                    <Col md="6">
                                        <p style={{ fontWeight: '800', fontSize: '1.2rem' }}>
                                            시간
                                        </p>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md="6">
                                        <Input
                                            type="date"
                                            style={{ height: '40px', borderRadius: '8px', fontSize: '0.9rem' }}
                                            value={data.date}
                                            dateFormat={'YYYY-MM-DD'}
                                            onChange={(e) =>
                                                setData({ ...data, date: e.target.value })
                                            }
                                        />
                                    </Col>
                                    <Col md="6">
                                        <Input
                                            type="time"
                                            style={{ height: '40px', borderRadius: '8px', fontSize: '0.9rem' }}
                                            value={data.time}
                                            dateFormat={'HH:mm:ss'}
                                            onChange={(e) =>
                                                setData({ ...data, time: e.target.value })
                                            }
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col style={{ margin: '0 auto', textAlign: 'center' }}>
                                        <Button
                                            style={{
                                                lineHeight: '50px',
                                                width: '25%',
                                                backgroundColor: '#5479f7',
                                                borderRadius: '50px',
                                                fontSize: '1.4rem',
                                                fontWeight: '800',
                                                margin: '5% 0 7% 0'
                                            }}
                                            onClick={(e) => submit()}
                                        >
                                            다음
                                        </Button>
                                    </Col>
                                </Row>
                            </>
                        }
                        {success && info && isCheck?.role === '개인' &&
                            <>
                                <Row>
                                    <Col md="12">
                                        <Row>
                                            <Col className="text-center" style={{ marginTop: '8%' }}>
                                                <h1 className="font-weight-bolder">상담 예약 완료</h1>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col style={{ margin: '5% auto 3%', borderTop: '2px solid black' }}/>
                                </Row>
                                <Row>
                                    <Col style={{ margin: '0 auto', textAlign: 'center' }}>
                                        <img src={successReverv} style={{ width: '15%' }}/>
                                        <h1>
                                            {userName?.name} 의 <span style={{ fontWeight: '900' }}>상담 예약 접수</span>되었습니다.
                                        </h1>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col style={{ margin: '0 auto', textAlign: 'center' }}>
                                        <h5>
                                            {userName?.name} 의 예약이 접수되었습니다.<br/>
                                            접수일 기준 2~3일 이내 지역 담당자를 배정하여 방문 전 연락드릴 예정입니다.
                                        </h5>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col style={{ margin: '0 auto', textAlign: 'center' }}>
                                        <Button
                                            style={{
                                                lineHeight: '50px',
                                                width: '25%',
                                                backgroundColor: '#5479f7',
                                                borderRadius: '50px',
                                                fontSize: '1.4rem',
                                                fontWeight: '800',
                                                margin: '5% 0 7% 0'
                                            }}
                                            onClick={(e) => history.push('/home')}
                                        >
                                            메인으로 이동
                                        </Button>
                                    </Col>
                                </Row>
                            </>
                        }
                        {success && info &&
                            <>
                                <Row>
                                    <Col md="12">
                                        <Row>
                                            <Col className="text-center" style={{ marginTop: '8%' }}>
                                                <h1 className="font-weight-bolder">상담 예약 완료</h1>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col style={{ margin: '5% auto 3%', borderTop: '2px solid black' }}/>
                                </Row>
                                <Row>
                                    <Col style={{ margin: '0 auto', textAlign: 'center' }}>
                                        <img src={successReverv} style={{ width: '15%' }}/>
                                        <h1>
                                            {userName?.name} 의 <span style={{ fontWeight: '900' }}>상담 예약 접수</span>되었습니다.
                                        </h1>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col style={{ margin: '0 auto', textAlign: 'center' }}>
                                        <h5>
                                            {userName?.name} 의 예약이 접수되었습니다.<br/>
                                            접수일 기준 2~3일 이내 지역 담당자를 배정하여 방문 전 연락드릴 예정입니다.
                                        </h5>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col style={{ margin: '0 auto', textAlign: 'center' }}>
                                        <Button
                                            style={{
                                                lineHeight: '50px',
                                                width: '25%',
                                                backgroundColor: '#5479f7',
                                                borderRadius: '50px',
                                                fontSize: '1.4rem',
                                                fontWeight: '800',
                                                margin: '5% 0 7% 0'
                                            }}
                                            onClick={(e) => history.push('/home')}
                                        >
                                            메인으로 이동
                                        </Button>
                                    </Col>
                                </Row>
                            </>
                        }
                    </Col>
                </div>
            }
        </>
    )
}

export default Reservation;
