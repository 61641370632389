import IndexHeader from "../../../components/Headers/IndexHeader";
import {Button, Col, Input, Row} from "reactstrap";
import AdminFooter from "../../../components/Footers/AdminFooter";
import {useState} from "react";
import {checkGreen} from "../../../assets/images";
import {isMobile} from "react-device-detect";

function Reset() {
    const [type, setType] = useState('login')

    return (
        <>
            <IndexHeader />
            {isMobile
            ?
                <div style={{ fontFamily: 'regular' }}>
                    <div
                        style={{ textAlign: 'center', fontSize: '2rem', fontWeight: '900', margin: '15% 0' }}
                    >
                        비밀번호 초기화
                    </div>
                    <div className="mb-4" style={{ textAlign: 'center' }}>
                        <Col md="4">
                            <span style={{ margin: '0 auto' }}>
                                <img src={checkGreen} style={{ height: '30px', margin: '0 5px', paddingBottom: '2%' }}/>
                                <span style={{ fontWeight: '500' }}>
                                    가입시 입력하신 휴대폰 번호를 통해 인증 후<br/>새 비밀번호를 등록해주세요.
                                </span>
                            </span>
                        </Col>
                    </div>
                    <div style={{ marginBottom: '3%' }}>
                        <Col>
                            <span style={{ fontWeight: '800', fontSize: '1.3rem' }}>
                                아이디
                            </span>
                        </Col>
                    </div>
                    <div>
                        <Col md="4">
                            <Input
                                style={{ lineHeight: '40px', fontSize: '1.1rem', fontFamily: 'regular' }}
                                placeholder="아이디 입력"
                                type="text"
                            />
                        </Col>
                    </div>
                    <div style={{ margin: '6% 0 3%' }}>
                        <Col>
                            <span style={{ fontWeight: '800', fontSize: '1.3rem', fontFamily: 'regular' }}>
                                휴대폰 번호
                            </span>
                        </Col>
                    </div>
                    <div>
                        <Col md="4">
                            <Input
                                style={{ lineHeight: '40px', fontSize: '1.1rem', fontFamily: 'regular' }}
                                placeholder="숫자만 입력"
                                type="text"
                            />
                        </Col>
                    </div>
                    <div className="mt-4">
                        <Col md="4">
                            <Input
                                style={{ lineHeight: '40px', fontSize: '1.1rem', fontFamily: 'regular' }}
                                placeholder="인증번호 6자리 숫자 입력"
                                type="text"
                            />
                        </Col>
                    </div>
                    <div style={{ margin: '15% 0' }}>
                        <Col md="4">
                            <Button
                                style={{
                                    lineHeight: '40px',
                                    width: '100%',
                                    backgroundColor: '#5479f7',
                                    borderColor: '#5479f7',
                                    borderRadius: '30px',
                                    fontSize: '1.2rem',
                                    fontWeight: 'bold',
                                    fontFamily: 'regular'
                                }}
                            >
                                인증하기
                            </Button>
                        </Col>
                    </div>
                </div>
            :
                <div style={{ fontFamily: 'regular' }}>
                    <Row className="text-center">
                        <Col style={{ margin: '5% 0' }}>
                            <h1
                                style={{ fontWeight: '800' }}
                            >
                                비밀번호 초기화
                            </h1>
                        </Col>
                    </Row>
                    <Row className="mb-4">
                        <Col md="4"/>
                        <Col md="4">
                            <Row>
                                <img src={checkGreen} style={{ height: '25px', margin: '0 10px' }}/>
                                <h5
                                    style={{ fontWeight: '500' }}
                                >
                                    가입시 입력하신 휴대폰 번호를 통해 인증 후 새 비밀번호를 등록해주세요.
                                </h5>
                            </Row>
                        </Col>
                        <Col md="4"/>
                    </Row>
                    <Row>
                        <Col md="4"/>
                        <Col md="4">
                            <h3
                                style={{ fontWeight: '800' }}
                            >
                                아이디
                            </h3>
                        </Col>
                        <Col md="4"/>
                    </Row>
                    <Row>
                        <Col md="4"/>
                        <Col md="4">
                            <Input
                                style={{ lineHeight: '40px', fontSize: '1.1rem', fontFamily: 'regular' }}
                                placeholder="아이디 입력"
                                type="text"
                            />
                        </Col>
                        <Col md="4"/>
                    </Row>
                    <Row className="mt-5">
                        <Col md="4"/>
                        <Col md="4">
                            <h3
                                style={{ fontWeight: '800' }}
                            >
                                휴대폰 번호
                            </h3>
                        </Col>
                        <Col md="4"/>
                    </Row>
                    <Row>
                        <Col md="4"/>
                        <Col md="4">
                            <Input
                                style={{ lineHeight: '40px', fontSize: '1.1rem', fontFamily: 'regular' }}
                                placeholder="숫자만 입력"
                                type="text"
                            />
                        </Col>
                        <Col md="4"/>
                    </Row>
                    <Row className="mt-4">
                        <Col md="4"/>
                        <Col md="4">
                            <Input
                                style={{ lineHeight: '40px', fontSize: '1.1rem', fontFamily: 'regular' }}
                                placeholder="인증번호 6자리 숫자 입력"
                                type="text"
                            />
                        </Col>
                        <Col md="4"/>
                    </Row>
                    <Row className="mt-5 mb-5">
                        <Col md="4"/>
                        <Col md="4">
                            <Button
                                style={{
                                    lineHeight: '40px',
                                    width: '100%',
                                    backgroundColor: '#5479f7',
                                    borderColor: '#5479f7',
                                    borderRadius: '30px',
                                    fontSize: '1.2rem',
                                    fontWeight: 'bold',
                                    fontFamily: 'regular'
                                }}
                            >
                                인증하기
                            </Button>
                        </Col>
                        <Col md="4"/>
                    </Row>
                </div>
            }
            <AdminFooter/>
        </>
    )
}

export default Reset