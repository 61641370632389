import {Col, Row} from "reactstrap";
import {nextBlue, stepImage, stepInfo, stepLogo} from "../../../assets/images";
import {useHistory} from "react-router-dom";
import {isMobile} from "react-device-detect";
import {stepImageM} from "../../../assets/imagesM";
import React from "react";

function Step () {
    const history = useHistory()

    return (
        <>
            {isMobile
            ?
                <div style={{ fontSize: '2rem', fontWeight: '800', textAlign: 'center' }}>
                    <img src={stepImageM}/>
                </div>
            :
                <Col md="8" style={{ margin: '0 auto', fontFamily: 'regular' }}>
                    <Row>
                        <Col md="2">
                            <Col className="border p-1 mt-4" style={{ borderRadius: '15px' }}>
                                <Col className="border-bottom">
                                    <p
                                        style={{ fontSize: '1rem', color: '#5479f7', fontWeight: 'bold' }}
                                        className="my-2"
                                    >
                                        지원절차
                                        <span className="ml-2">
                                            <img src={nextBlue} style={{ width: '8%' }}/>
                                        </span>
                                    </p>
                                </Col>
                                <Col>
                                    <p
                                        style={{ fontSize: '0.8rem', color: '#5c5c5c', fontWeight: 'bold' }}
                                        className="my-2"
                                    >
                                        지원절차
                                    </p>
                                </Col>
                            </Col>
                        </Col>
                        <Col md="10" className="mt-3">
                            <Row className="pl-1 mt-2 mb-5">
                                <Col>
                                    <img src={stepInfo} style={{ width: '11.5%' }}/>
                                </Col>
                            </Row>
                            <Row className="pl-4">
                                <Col className="text-center">
                                    <img src={stepLogo} style={{ width: '90%' }}/>
                                </Col>
                            </Row>
                            <Row className="pl-4" style={{ marginBottom: '10%' }}>
                                <Col className="text-center">
                                    <img src={stepImage} style={{ width: '90%' }}/>
                                </Col>
                            </Row>
                            <Row>
                                <Col md="4">

                                </Col>
                                <Col md="4">

                                </Col>
                                <Col md="4">

                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Col>
            }
        </>
    )
}

export default Step;