import IndexHeader from "../../../components/Headers/IndexHeader";
import {Button, Col, Input, Row} from "reactstrap";
import AdminFooter from "../../../components/Footers/AdminFooter";
import {useState} from "react";
import {checkGreen} from "../../../assets/images";
import {isMobile} from "react-device-detect";
import AuthService from "./service/auth.service";

function Find() {
    const [type, setType] = useState('login')
    const [tel, setTel] = useState('')
    const [send, setSend] = useState(false)
    const [message, setMessage] = useState()
    const [confirm, setConfirm] = useState(false)

    console.log(tel)

    const sendCode = async (type) => {
        try {
            const res = await AuthService.sendCode(tel, type);
            if (res.statusCode === 200) {
                alert('인증번호를 전송했습니다.')
                setSend(true)
            }
        } catch (e) {
            alert(e.response?.data?.message);
        }
    };

    const confirmCode = async () => {
        try {
            const res = await AuthService.confirm(tel, message);
            if (res.statusCode === 200) {
                alert('인증 완료되었습니다.')
                setConfirm(true)
            }
        } catch (e) {
            alert(e.response?.data?.message);
        }
    };


    return (
        <>
            <IndexHeader />
            {isMobile
            ?
                <div style={{ fontFamily: 'regular' }}>
                    <div
                        style={{ textAlign: 'center', fontSize: '2rem', fontWeight: '900', margin: '15% 0' }}
                    >
                        아이디 찾기
                    </div>
                    <div className="mb-4" style={{ textAlign: 'center' }}>
                        <Col md="4">
                            <span style={{ margin: '0 auto' }}>
                                <img src={checkGreen} style={{ height: '30px', margin: '0 5px', paddingBottom: '2%' }}/>
                                <span style={{ fontWeight: '500' }}>
                                    가입시 입력하신 휴대폰 번호를 통해 찾을 수 있습니다.
                                </span>
                            </span>
                        </Col>
                    </div>
                    <div style={{ marginBottom: '3%' }}>
                        <Col>
                            <span style={{ fontWeight: '800', fontSize: '1.3rem' }}>
                                휴대폰 번호
                            </span>
                        </Col>
                    </div>
                    <div>
                        <Col md="4">
                            <Input
                                style={{ lineHeight: '40px', fontSize: '1.1rem' }}
                                placeholder="숫자만 입력"
                                type="text"
                                name="tel"
                                value={tel}
                                onChange={(e) => setTel(e.target.value.replace(/[^0-9]/g, ''))}
                            />
                        </Col>
                    </div>
                    <div className="mt-4">
                        <Col md="4">
                            <Input
                                style={{ lineHeight: '40px', fontSize: '1.1rem' }}
                                placeholder="인증번호 6자리 숫자 입력"
                                type="text"
                            />
                        </Col>
                    </div>
                    <div style={{ margin: '15% 0' }}>
                        <Col md="4">
                            <Button
                                style={{
                                    lineHeight: '40px',
                                    width: '100%',
                                    backgroundColor: '#5479f7',
                                    borderColor: '#5479f7',
                                    borderRadius: '30px',
                                    fontSize: '1.2rem',
                                    fontWeight: 'bold'
                                }}
                            >
                                인증하기
                            </Button>
                        </Col>
                    </div>
                </div>
            :
                <div style={{ fontFamily: 'regular', padding: '10% 0' }}>
                    <Row className="text-center">
                        <Col style={{ marginBottom: '5%' }}>
                            <h1
                                style={{ fontWeight: '800' }}
                            >
                                아이디 찾기
                            </h1>
                        </Col>
                    </Row>
                    <Row className="mb-4">
                        <Col md="4"/>
                        <Col md="4">
                            <Row>
                                <img src={checkGreen} style={{ height: '25px', margin: '0 10px' }}/>
                                <h5
                                    style={{ fontWeight: '500' }}
                                >
                                    가입시 입력하신 휴대폰 번호를 통해 찾을 수 있습니다.
                                </h5>
                            </Row>
                        </Col>
                        <Col md="4"/>
                    </Row>
                    <Row>
                        <Col md="4"/>
                        <Col md="4">
                            <h3
                                style={{ fontWeight: '800' }}
                            >
                                휴대폰 번호
                            </h3>
                        </Col>
                        <Col md="4"/>
                    </Row>
                    <Row>
                        <Col md="4"/>
                        <Col md="4">
                            {send
                                ?
                                <Input
                                    style={{ lineHeight: '50px', fontSize: '1.1rem', borderRadius: '8px' }}
                                    placeholder="숫자만 입력"
                                    type="text"
                                    name="tel"
                                    value={tel}
                                    // onChange={(e) => setTel(e.target.value.replace(/[^0-9]/g, ''))}
                                    readOnly
                                />
                                :
                                <Input
                                    style={{ lineHeight: '50px', fontSize: '1.1rem', borderRadius: '8px' }}
                                    placeholder="숫자만 입력"
                                    type="text"
                                    name="tel"
                                    value={tel}
                                    onChange={(e) => setTel(e.target.value.replace(/[^0-9]/g, ''))}
                                />
                            }
                        </Col>
                        <Col md="4"/>
                    </Row>
                    {send &&
                        <Row className="mt-4">
                            <Col md="4"/>
                            <Col md="4">
                                <Input
                                    style={{ lineHeight: '40px', fontSize: '1.1rem' }}
                                    placeholder="인증번호 6자리 숫자 입력"
                                    type="text"
                                    name="message"
                                    value={message}
                                    onChange={(e) => setMessage(e.target.value.replace(/[^0-9]/g, ''))}
                                />
                            </Col>
                            <Col md="4"/>
                        </Row>
                    }
                    {send ?
                        <Row className="mt-5 mb-5">
                            <Col md="4"/>
                            <Col md="4">
                                <Button
                                    style={{
                                        lineHeight: '40px',
                                        width: '100%',
                                        backgroundColor: '#5479f7',
                                        borderColor: '#5479f7',
                                        borderRadius: '30px',
                                        fontSize: '1.2rem',
                                        fontWeight: 'bold'
                                    }}
                                    onClick={(e) => {
                                        confirmCode();
                                    }}
                                >
                                    인증하기
                                </Button>
                            </Col>
                            <Col md="4"/>
                        </Row>
                        :
                        <Row className="mt-5 mb-5">
                            <Col md="4"/>
                            <Col md="4">
                                <Button
                                    style={{
                                        lineHeight: '40px',
                                        width: '100%',
                                        backgroundColor: '#5479f7',
                                        borderColor: '#5479f7',
                                        borderRadius: '30px',
                                        fontSize: '1.2rem',
                                        fontWeight: 'bold'
                                    }}
                                    onClick={(e) => {
                                        if(!tel) {
                                            return alert('휴대폰 번호를 입력해주세요.')
                                        }
                                        sendCode('join')
                                    }}
                                >
                                    보내기
                                </Button>
                            </Col>
                            <Col md="4"/>
                        </Row>
                    }
                    {confirm
                        ?
                        <>
                        </>
                        :
                        <>
                        </>
                    }
                </div>
            }
            <AdminFooter/>
        </>
    )
}

export default Find;